// src/components/Login.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../../assets/css/admin-style.css"
const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');



  return <>
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img alt="hey" src="image/ABVV-NewLogo.png" className="img-fluid" style={{borderRadius:"50%"}} />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form className="login-form">
              <div className="d-flex align-items-center my-4">
                <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
              </div>
              {/* <!-- Email input --> */}
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">Email address</label>
                <input type="email" id="form3Example3" className="form-control form-control-lg"
                  placeholder="Enter a valid email address" />
              </div>

              {/* <!-- Password input --> */}
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">Password</label>
                <input type="password" id="form3Example4" className="form-control form-control-lg"
                  placeholder="Enter password" />
              </div>

              <div className="d-flex justify-content-between align-items-center">
                {/* <!-- Checkbox --> */}
                <div className="form-check mb-0">
                  <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>
                <Link to="/reset-password" className="text-body">Forgot password?</Link>
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <Link to="/" type="button" className="btn btn-primary btn-lg">Login</Link>
                {/* <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="#!"
                  className="link-danger">Register</a></p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  </>
}
;

export default AdminLogin;
