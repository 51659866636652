// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const ViceChancellor= observer(() => {
  return (
    <div>
      <Header/>
        <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="width100">
      <div className="container-fluid">
        <div className="row2">
          <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
            <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
              <h3 className="main-head">Administration</h3>
              <ul
                className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                id="menu"
              >
                <li className="w-100">
                  <a
                    href="/HonbleChancellor"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Hon'ble Chancellor
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu1"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-speedometer2" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Vice Chancellor
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu1"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/ViceChancellor"
                        className="nav-link px-0"
                        style={{
                          borderLeft: "4px solid #03356E",
                          backgroundColor: "#03356E",
                          pointerEvents: "none"
                        }}
                      >
                        {" "}
                        <span
                          className=" d-sm-inline asd"
                          style={{ color: "#fff" }}
                        >
                          Vice Chancellor
                        </span>{" "}
                      {" "}
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="/FormerViceChancellor"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Former Vice Chancellor
                        </span>{" "}
                      {" "}
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="w-100">
                  <a
                    href="/Registrar"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">Registrar</span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu2"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle "
                  >
                    <i className="fs-4 bi-bootstrap" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Executive Council
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu2"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/ECMember"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Member Of The Executive Council
                        </span>{" "}
                      
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="/ECNotification"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Notification And Minutes Of Meeting Of The Executive
                          Council
                        </span>{" "}
                      
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu3"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-grid" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Academic Council
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu3"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/ACMember"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Member Of The Academc Council
                        </span>{" "}
                      
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="/ACNotification"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Notification And Minutes Of Meeting Of The Academic
                          Council
                        </span>{" "}
                      
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="w-100">
                  <a
                    href="/DirectorPhysicalEducation"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Director,Physical Education
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/ControllerOfExamination"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Controller Of Examination
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/FinanceOfficer"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Finance Officer
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/Deputyregistrar"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Deputy Registrar
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/AssistantRegistrar"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Assistant Registrar
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/DeanOfStudentsWelfare"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Dean Of Students' Welfare
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu4"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-grid" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Finance Committee
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu4"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/FCMember"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Member Of The Finance Committee
                        </span>{" "}
                      
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="/FCNotification"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Notification And Minutes Of Meeting Of The Academic
                          Council
                        </span>{" "}
                      
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu5"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-grid" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Dean Of Schools
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu5"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/DOSMember"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Member Of The Dean Of Schools
                        </span>{" "}
                      
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="/DOSNotification"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Notification And Minutes Of Meeting Of The Dean Of
                          Schools
                        </span>{" "}
                      
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu6"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-grid" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Board Of Studies
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu6"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/BOSMember"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Member Of The Board Of Studies
                        </span>{" "}
                      
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="/BOSNotification"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Notification And Minutes Of Meeting Of The Board Of
                          Studies
                        </span>{" "}
                      
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu7"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-grid" />{" "}
                    <span className="ms-1  d-sm-inline gd">
                      Different Cells
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu7"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="/DCTrainingcell"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline ">
                          Training And Placement Cell
                        </span>{" "}
                      
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <hr />
            </div>
          </div>
          <div className="row1 mx-auto">
            <h3 className="main-head">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
              </svg>
              Vice Chancellor
            </h3>
            <div className="vc-img">
              <img
                src="image/Prof-ADN-Bajpai.png"
                alt="Prof. A. D. N. Bajpai"
              />
            </div>
            <div className="vc-profile">
              <h3>Prof. A. D. N. Bajpai</h3>
              <p>Vice Chancellor</p>
            </div>
            <div className="content">
              <div className="content-head"></div>
              <div className="para">
                <h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="16px"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>{" "}
                  vc@bilaspuruniversity.ac.in
                </h4>
                <br />
                <p>
                  To attain the knowledge in real sense is the first and last
                  goal of human life but it is the difficult task to define the
                  knowledge it has so many approaches. Atal Bihari Vajpayee
                  Vishwavidyalaya has accepted a new vision to define and
                  identify the knowledge that skill of each and every work will
                  automatically realize the sense of knowledge, it means if we
                  do our prescribed work with honestly and proper skill
                  knowledge will nationally take a shape and a man will find it
                  easily. Basically, in Gita lord Krishna suggested Arjun as a
                  disciple that skilful action is itself a form of Yoga. It
                  means Yoga does not mean ankle exercises of body or a physical
                  practice but of Yoga doing a work skilfully is also a form of
                  Yoga. actually, doing a work with skill and sense of honesty
                  is real attainment of knowledge which is ever pious.
                  <br />
                  <br />
                  Skill of work contains so many elements in itself pure
                  concentration and realless efforts can be counted its
                  permanent components. Symbol of University is constituted to
                  promote the real skill of work among the region of
                  Chhattisgarh. And ultimate goal of university is to create the
                  real skill in natives of Chhattisgarh. University also wants
                  to spread this immortal message that pious knowledge can be
                  attained with a practice of skilful action.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
<Footer/>

    </div>
  );
}
);

export default ViceChancellor;