// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const ViceChancellorsMessage= observer(() => {
  return (
    <div>
      <Header/>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">vice-chancellors-message</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/AboutAbvv"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About ABVV</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutBilaspur"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Bilaspur</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ViceChancellorsMessage"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    Vice Chancellor's Message
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutKulgeet"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Kulgeet</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Recognition"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Recogenition</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            Vice Chancellor's Message
          </h3>
          <div className="vc-img">
            <img className="img1" src="image/Prof-ADN-Bajpai.png" alt="Prof. A. D. N. Bajpai" />
          </div>
          <div className="vc-profile">
            <h3>Prof. A. D. N. Bajpai</h3>
            <p>Vice Chancellor</p>
          </div>
          <div className="content">
            <div className="content-head">
              <h3>Former Vice Chancellor'S DESK</h3>
            </div>
            <div className="para">
              <p>
                To attain the knowledge in real sense is the first and last goal
                of human life but it is the difficult task to define the
                knowledge it has so many approaches. Atal Bihari Vajpayee
                Vishwavidyalaya has accepted a new vision to define and identify
                the knowledge that skill of each and every work will
                automatically realize the sense of knowledge, it means if we do
                our prescribed work with honestly and proper skill knowledge
                will nationally take a shape and a man will find it easily.
                Basically, in Gita lord Krishna suggested Arjun as a disciple
                that skilful action is itself a form of Yoga. It means Yoga does
                not mean ankle exercises of body or a physical practice but of
                Yoga doing a work skilfully is also a form of Yoga. actually,
                doing a work with skill and sense of honesty is real attainment
                of knowledge which is ever pious.
                <br />
                <br />
                Skill of work contains so many elements in itself pure
                concentration and realless efforts can be counted its permanent
                components. Symbol of University is constituted to promote the
                real skill of work among the region of Chhattisgarh. And
                ultimate goal of university is to create the real skill in
                natives of Chhattisgarh. University also wants to spread this
                immortal message that pious knowledge can be attained with a
                practice of skilful action.
              </p>
            </div>
            <div className="bst-wishes">
              <span>Best Wishes</span>
              <h1>Prof. A. D. N. Bajpai</h1>
              <h3>(Vice Chancellor)</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<Footer/>

    </div>
  );
}
);

export default ViceChancellorsMessage;