import React, { useRef } from 'react';
import NewsTicker from 'react-advanced-news-ticker';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS
const NewsTickerComponent = ({ newsData }) => {
    console.log(newsData);
    const tickerRef = useRef(null);

    const handleMovePrev = () => {
        if (tickerRef.current) {
            tickerRef.current.movePrev();
            tickerRef.current.resetInterval();
        }
    };

    const handleMoveNext = () => {
        if (tickerRef.current) {
            tickerRef.current.moveNext();
            tickerRef.current.resetInterval();
        }
    };

    return (
        <div className="news-ticker-container">
            <i className="fa fa-arrow-up news-ticker-control pointer" onClick={handleMovePrev} />
            <div className="news-ticker-wrapper">
                <NewsTicker
                    ref={tickerRef}
                    rowHeight={30}
                    maxRows={10}
                    speed={600}
                    duration={4000}
                    autoStart={true}
                    pauseOnHover={true}
                    style={{ marginTop: 5 }}
                >
                    {newsData?.length > 0 ? newsData?.map((news, ind) => (
                        <div className='news-item' key={ind + "news"}>
                            <div>{news.date}</div>
                            <div>{news.title}</div>
                        </div>
                    )) : <span>No New Notification!</span>}
                </NewsTicker>

            </div>
            <i className="fa fa-arrow-down news-ticker-control pointer" onClick={handleMoveNext} />
        </div>
    );
};

export default NewsTickerComponent;
