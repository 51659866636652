// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const HonbleChancellor= observer(() => {
  return (
    <div>
      <Header/>
      <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Administration</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/HonbleChancellor"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    Hon'ble Chancellor
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu1"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-speedometer2" />{" "}
                  <span className="ms-1  d-sm-inline gd">Vice Chancellor</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu1"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/ViceChancellor"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Vice Chancellor
                      </span> 1{" "}
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/FormerViceChancellor"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Former Vice Chancellor
                      </span>{" "}
                      {" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="/Registrar"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">Registrar</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu2"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle "
                >
                  <i className="fs-4 bi-bootstrap" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Council
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu2"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/ECMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Member Of The Executive Council
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/ECNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Executive
                        Council
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu8"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">Academic Council</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu8"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/ACMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Member Of The Academc Council
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/ACNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Academic
                        Council
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="/DirectorPhysicalEducation"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Director,Physical Education
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ControllerOfExamination"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Controller Of Examination
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/FinanceOfficer"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">Finance Officer</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Deputyregistrar"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">Deputy Registrar</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AssistantRegistrar"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Assistant Registrar
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/DeanOfStudentsWelfare"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Dean Of Students' Welfare
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu4"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Finance Committee
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu4"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/FCMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Member Of The Finance Committee
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/FCNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Academic
                        Council
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu5"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">Dean Of Schools</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu5"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/DOSMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Member Of The Dean Of Schools
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/DOSNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Dean Of
                        Schools
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu6"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">Board Of Studies</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu6"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/BOSMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Member Of The Board Of Studies
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/BOSNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Board Of
                        Studies
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu7"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">Different Cells</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu7"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/DCTrainingcell"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline ">
                        Training And Placement Cell
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            Hon'ble Chancellor
          </h3>
          <div className="card card1 mx-auto">
            <div className="vc-img w-100 h-100">
              <img
                src="image/HonGovernorCG (1).jpeg"
                alt="Prof. A. D. N. Bajpai"
              />
            </div>
            <div className="vc-profile w-100 h-100">
              <h3 className='w-100'>श्री विश्वभूषण हरिचंदन</h3>
            </div>
            <div className="content-head-hs w-100 h-100">
              <div className="w-100 h-100">
                <p>
                  महामहिम राज्यपाल,छत्तीसगढ़ एवं कुलाधिपति
                  <br />
                  अटल बिहारी वाजपेयी विश्वविद्यालय ,बिलासपुर
                </p>
              <div className="btn1 w-100">
                <button className="btn w-100">details</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default HonbleChancellor;