// src/pages/DashboardPage.js
import React from "react";
import DataTable from "../components/CustomDataTable";
import CustomDataTable from "../components/CustomDataTable";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

const PagesList = () => {





    const handleEdit = ()=>{

    }
    const handleDelete = ()=>{

    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Page Name',
            selector: row => row.page_name,
            sortable: true,
            
        },
        {
            name: 'Action',
            cell: (row) => (
              <div className="d-flex  align-items-center">
                <button className="btn btn-sm btn-outline-primary" onClick={() => handleEdit(row)}><i className="far fa-edit"></i></button>
                <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(row)}><i className="far fa-trash-alt"></i></button>
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
    ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper" style={{ width: "100%" }}>
        <Header />

        <div className="container">
          <div className="content-wrapper p-3 bg-white  d-flex justify-content-between">
            <h3 className="page-title">
              <span className="page-title-icon linear text-white me-2">
                <i className="fas fa-user-friends"></i>
              </span>{" "}
              Page Records
            </h3>
            <div className="d-flex justify-content-lg-end pt-lg-0 pt-2">
              <h1 className="page-title signup-link fs-4">
                <span className="page-title-icon linear text-white me-2">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Pages
              </h1>
            </div>
          </div>
          <CustomDataTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

export default PagesList;
