// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const AboutKulgeet= observer(() => {
  return (
    <div>
    <Header/>
  <section className="innerbanner" />
  <div style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
            <h3 className="main-head">About Kulgeet</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/AboutAbvv"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About ABVV</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutBilaspur"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Bilaspur</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ViceChancellorsMessage"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Vice Chancellor's Message
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutKulgeet"
                  className="nav-link px-0 align-middle"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    About Kulgeet
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Recognition"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Recogenition</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            ABVV Kulgeet
          </h3>
          <div className="content">
            <h1>कुलगीत</h1>
            <p className="p1">
              बिलासा में बिलसता विश्वविद्यालय अटल है।
              <br />
              महामाया की किरपा बरसती जिस पर धवल है।।
              <br />
              हमारा विश्वविद्यालय अटल है।
            </p>
            <p className="p2">
              ‘योगः कौशलं कर्मसु’ से मंत्रित ज्ञान का मन्दिर।
              <br />
              सिंचा सिउनाथ, अरपा सलिल से विज्ञान का मन्दिर।।
              <br />
              गढ़ा-छत्तीसगढ़ के गढ़ में ‘नालन्दा-नवल’ है।
              <br />
              हमारा विश्वविद्यालय अटल है।
            </p>
            <p className="p3">
              शहीदों , सन्त, कवियों की ये माटी ‘काले हीरों’ की।
              <br />
              जँवारा, चतुरगढ़, मल्हार संस्कृति की नज़ीरों की।।
              <br />
              सनातन सृजन के सन्धान का संकुल-सकल है।
              <br />
              हमारा विश्वविद्यालय अटल है।
            </p>
            <p className="p4">
              पर्वत-काननों से सुसज्जित आदि-सभ्यता से।
              <br />
              प्रकृति की भव्यता से, दिव्यता से, नव्यता से।।
              <br />
              उदधि सा धीर, व्यापक व्योम सा, भू सा अचल है।
              <br />
              पवन सा सतत सक्रिय, प्रखर रवि सा जो प्रबल है।।
              <br />
              हमारा विश्वविद्यालय अटल है।।
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
<Footer/>

    </div>
  );
}
);

export default AboutKulgeet;