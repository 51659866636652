// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const AboutAbvv= observer(() => {
  return (
    <div>
     <Header/>
  <section className="innerbanner" />
  <div style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
            <h3 className="main-head">About ABVV</h3>
            <ul
              className="nav nav-pills mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/AboutAbvv"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    About ABVV
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutBilaspur"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Bilaspur</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ViceChancellorsMessage"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Vice Chancellor's Message
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutKulgeet"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Kulgeet</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Recognition"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Recogenition</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            About ABVV
          </h3>
          <div className="img-container1 w-100">
            <img src="image/ABVVIMG.jpg" alt="abvvimg" />
          </div>
          <p className="p-head">
            Towards an Excellence through Equity, Atal Bihari Vajpayee
            Vishwavidyalaya was established in June, 2012 though it was gazetted
            on 3rd February 2012. After its establishment, based on priority,
            the University has prepared the Road map as follows.
          </p>
          <div className="content">
            <h3 className="cont-head">VISION</h3>
            <ul>
              <li>
                Towards an Excellence through Equity, Access and Quality
                Education.
              </li>
            </ul>
            <h3 className="cont-head">MISSION</h3>
            <ul>
              <li>
                Aspire to translate collective dream of the Community of the
                region in to reality.
              </li>
              <li>
                Create, disseminate and advance knowledge, through instructional
                and Inter disciplinary and collaborative researches.
              </li>
              <li>
                Educate and train the Human Resource persons for the development
                of the State of Chhattisgarh.
              </li>
              <li>
                Advancement of intellectual, academic, cultural and natural
                resource development for Socio- economic development of the
                region.
              </li>
              <li>
                Appropriate measures to promote quality education in affiliated
                colleges.
              </li>
            </ul>
            <h3 className="cont-head">OBJECTIVES</h3>
            <ul className="border-bottom">
              <li>
                To develop innovative and professional instructional programmes
                to cater the needs of students of the State of Chhattisgarh.
              </li>
              <li>
                To create an ambience for quality teaching-learning and skill
                development and its upgradation .
              </li>
              <li>
                To support students in developing competency in their respective
                fields of study to participate in emerging global economic
                opportunities.
              </li>
              <li>
                To identify the educational, social cultural &amp; industrial
                needs in the State and plan to create relevant programmes.
              </li>
              <li>
                To further develop infrastructure for continued productivity,
                competitiveness, of human resources of the University in
                creating knowledge and research programmes in time and space.
              </li>
              <li>
                To up keep the Administrative and academic reforms in emerging
                educational and research programmes.
              </li>
              <li>
                To support teaching, research and Autonomy in the colleges to
                emerge as potential of excellence.
              </li>
            </ul>
            <p className="p-content">
              Atal Bihari Vajpayee Vishwavidyalaya (Vishwavidyalaya) is State
              University which has been established by the Gazette notification
              on 03.02.2012 of Chhattisgarh Act No 07, 2012,
            </p>
            <p className="p-content">
              The Chhattisgarh Vishwavidyalaya ( Amendment) Act, 2011 and came
              in to its existence in June, 2012. The jurisdiction of Atal Bihari
              Vajpayee Vishwavidyalaya is extended to 04 districts namely:-
              revenue districts of Bilaspur, Mungeli , Korba,
              Gaurela-Pendra-Marwahi.
            </p>
            <p className="p-content">
              The University is situated on In Front of Koni Police Thana
              ,Bilaspur- Ratanpur Road,Koni ,Bilaspur (C.G) 495009. There are
              nearly 95 Govt. and private colleges affiliated in the 04
              districts of Chhattisgarh State which together constitute the
              jurisdiction of Atal Bihari Vajpayee Vishwavidyalaya. The colleges
              carry out undergraduate and postgraduate studies in different
              streams of Science, Arts, Commerce, Law and Education and Centres
              of research.
            </p>
            <p className="p-content">
              The University plans to develop strategy for enhancing the range
              of pedagogic approaches and the use of technology. As a part of
              this approach, the University plans to start classrooms into state
              of the art ones, enabling teachers to teach using wide variety of
              media.
            </p>
            <div className="table-content">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">S.N.</th>
                    <th scope="col">District </th>
                    <th scope="col">Govt. </th>
                    <th scope="col">Private </th>
                    <th scope="col">Total</th>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>Bilaspur </td>
                    <td>17</td>
                    <td>47</td>
                    <td>
                      <b>64</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Mungeli</td>
                    <td>10</td>
                    <td>10</td>
                    <td>
                      <b>20</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Gaurela-Pendra-Marwahi</td>
                    <td>05</td>
                    <td>08</td>
                    <td>
                      <b>13</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Korba </td>
                    <td>15</td>
                    <td>13</td>
                    <td>
                      <b>28</b>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Total
                    </th>
                    <th>
                      <b>47</b>
                    </th>
                    <th>
                      <b>78</b>
                    </th>
                    <th>
                      <b>125</b>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <Footer/>
    </div>
  );
}
);

export default AboutAbvv;