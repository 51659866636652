// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const AboutBilaspur = observer(() => {
  return (
    <div>
        <Header/>
      
  <section className="innerbanner" />
  <div style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
            <h3 className="main-head">About Bilaspur</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/AboutAbvv"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About ABVV</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutBilaspur"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    About Bilaspur
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ViceChancellorsMessage"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Vice Chancellor's Message
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutKulgeet"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Kulgeet</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Recognition"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Recogenition</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            About Bilaspur
          </h3>
          <h3 className="sub-head">
            Some Popular Locations in and around Bilaspur <br />
            Kotmisonar Crocodile Park
          </h3>
          <div className="subs">
            <p>
              A large natural tank has been turned into a crocodile park near
              Kotmisonar village in Janjgir district ,approximately 30
              kilometers away from Bilaspur. This step has been taken in order
              to save the villagers from being attacked by these amphibian
              creatures as well as protect them from being endangered species.
              The crocodile park in the Kotmi Sonar range houses nearly 150
              crocodiles picked up from various village ponds in the vicinity.
              The park with a steel fencing was developed with a sole motive, to
              stop the crocodile menace in the village. The crocodiles attacked
              the villagers whenever they came near the ponds, once they found
              that their natural prey had reduced. A couple of villagers lost
              their lives and several others got injured. To stop this threat,
              the village Panchayats through the assistance of State's
              conservator of forests decided to house all the crocodiles in a
              separate vast lake.
            </p>
            <img
              className="img-right"
              style={{ marginTop: "-25px" }}
              src="image/Kotmisonar Crocodile Park.jpg"
              alt="Kotmisonar"
            />
          </div>
          <h3 className="sub-head">Malhar City</h3>
          <div className="subs">
            <img
              className="img-left"
              src="image/malhar-temples-bilaspur.jpg"
              alt="Kotmisonar"
            />
            <p>
              Malhar Situated about 30 kms from Bilaspur City, today Malhar is
              an important archaelogical site. The temples dating back to the
              10th and 11th century have been excavated and salvaged here.
              Besides this, there are several sites here that have been marked
              for excavation by the Archaeology Department. Malhar used to be an
              important center during the Kulchuri regime. The archaelogical
              department has setup a museum where the many artifacts recovered
              during the excavations have been displayed. The twin villages of
              Malhar and Budikhar are littered with many old time artifacts
              which is very common place for the local residents. Amongst the
              prominent findings are the temples of Didneshwari, Pataleshwar and
              Dewari. The temple of Didneshwari is very popular and people from
              far and wide come to offer their prayers here. The beautiful black
              idol of Didneshwari Devi is really delight to see.
            </p>
          </div>
          <h3 className="sub-head">Achanakmar Wildlife Sanctuary</h3>
          <div className="subs">
            <p>
              Achanakmar Wildlife Sanctuary was constituted in 1975. It is a
              dense forest spread over an area of 555 km². About 40 kilometers
              from Bilaspur City Achanakmar is the natural habitat for many wild
              animals including the tiger, leopard, chital, sambhar, bear, gaur
              etc. A trip to the forest is a very pleasant experience and it has
              all the ingredients to quench anybody's thirst for adventure.
              Plenty of tree cover, rough terrain, wild animals. Watch towers
              have been erected at several places where the wild animals of the
              jungle are regularly sighted. You can perch yourself on any of the
              towers and keep watch. To visit the forest you can join the guided
              tours into the forest from Bilaspur that are organized by the
              forest department. Alternately, you can explore also the jungle in
              your own vehicle.
            </p>
            <img
              style={{ marginTop: "-60px" }}
              className="img-right"
              src="image/Achanakmar Wildlife Sanctuary.jpg"
              alt="Kotmisonar"
            />
          </div>
          <h3 className="sub-head">Ratanpur</h3>
          <div className="subs">
            <img
              className="img-left"
              src="image/maamahamayamandir.jpg"
              alt="Kotmisonar"
            />
            <p>
              The temple town, Ratanpur, is situated about 25 kilometers from
              Bilaspur City on the Bilaspur - Ambikapur Highway. Today it is
              recognized as a major religious center of the region that has
              national acclaim. Devotees visit the Mahamaya temple all round the
              year to offer their prayers and seek blessings of the deity from
              far and wide. The glorious past of the place made it a prime
              candidate for historians and archeologist. The fort of Ratanpur
              has been taken up for restoration by the Archeological Survey of
              India. The findings here put it on the national archeological map.
              The ruins of the fort, the palace (Badal Mahal) and some of the
              old temples can still be seen here. The founder of Ratanpur, King
              Ratnadev also built the Mahamaya temple. The temple has the dual
              idol of goddesses - Saraswati and Laxmi. Mahamaya temple has
              gained recognition as a Sidh Peeth and is the most popular
              religious places in the Chhattisgarh state.
            </p>
          </div>
          <h3 className="sub-head">Talagaon</h3>
          <div className="subs">
            <p>
              Talagaon is a small quite village, on the banks of Maniyari river.
              It is situated about 30 kms from Bilaspur, off the Bilaspur-Raipur
              Highway. This village came to limelight and got registered on the
              tourist map when the ancient picturesque Deorani-Jethani temples
              on the banks of the river were excavated. The highlight of
              Talagaon is the unique idol of Lord Shiva known as Rudrashiv
              (रुद्रशिव) which is in excellect condition. As for the rest of the
              temple, though the structures of the temples are not in very good
              shape whatever remains is evidence enough speaking volumes of the
              past magnificence of these temples. The carvings and statues on
              the walls of the temple depict stories of Lord Shiva and the
              entrances of the temples have several artistic statues of the
              deities. Off late, the area around the temples has been developed
              into a beautiful park keeping in view of the tourist traffic that
              the place has started attracting. New carvings are being done on
              the walls along the banks of river.
            </p>
            <img
              style={{ marginTop: "-15px" }}
              className="img-right"
              src="image/tala.png"
              alt="Kotmisonar"
            />
          </div>
          <h3 className="sub-head">Seepat NTPC</h3>
          <div className="subs">
            <img
              className="img-left"
              src="image/ntpc-sipat.jpg"
              alt="Kotmisonar"
            />
            <p>
              'Sipat' is a small developing town,approximately 22 kilometers
              away from Bilaspur, Chhattisgarh,the second largest city in
              Chhattisgarh. It has been in news due to setup of new power plant
              by NTPC Limited in that area. The project was started on 2001 by
              Indian former Prime Minister Mr. Atal Bihari Vajpayee.NTPC Sipat
              has total installed capacity of 2980 MW.NTPC Sipat has two stages:
              Stage-I comprises 3 units of 660MW each and Stage-II comprises 2
              units of 500MW each.The thermal power generation in NTPC sipat
              Stage-II is based on "Super Critical Boiler Technology" which is
              the advanced technology in thermal power generation. NTPC Limited
              has helped this town in developing by providing business
              prospective in that area and by providing education, healthcare
              facilities.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>


    </div>
  );
}
);

export default AboutBilaspur;