// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const Iqac= observer(() => {
  return (
    <div>
      <Header/>
  <nav className="navbar navbar-expand-lg bg-body-tertiary py-0 navbar1">
    <div className="container-fluid">
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#">
              Home
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              About IQAC
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Introduction
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  strategies
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  functions
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Benefits
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Composition NAAC Guidelines
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Committee
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  IQAC Committee
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  IQAC Core Committee
                </a>
              </li>
              <a className="dropdown-item" href="#"></a>
              <li>
                <a className="dropdown-item" href="#" />
                <a className="dropdown-item" href="#">
                  Steering Committee
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              activities
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  2017-18
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2018-19
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2019-20
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2020-21
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2021-22
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2022-23
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2023-24
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Meetings And Minutes
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Notification
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Minutes
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Academic And Administrative Audit
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  2017-18
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2018-19
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2019-20
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2020-21
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2021-22
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  2022-23
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              NAAC
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Institutional Best practices
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  IIQA
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Student Satisfaction Survey
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  SSR
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  feedback of different stakeholders
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      student
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      alumni's
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Employers
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Faculties
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Feedback Link
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
 
  <div className="pimg1"></div>
  <div className="ptext">
    <span className="border" style={{ filter: "none", WebkitFilter: "none" }}>
      Scroll Down
    </span>
  </div>
  <section className="section section-dark">
    <h2>Introduction of IQAC</h2>
    <p>
      The basic purpose of establishment of IQAC is quality sustenance and
      development of a system for conscious, consistent and catalytic action to
      improve the academic and administrative performance of the institution and
      to assure all the stake holders about the quality and capacity building
      programs provided in the institution. Further, Quality enhancement is a
      continuous process to channelize the efforts and the initiations of the
      institution towards academic excellence. It is a process of
      internalization and institutionalization of quality enhancement programs
      and practices either emulated from others or devised through ingenuity. It
      is a quality nurturing activity rather than controlling its success
      depends upon the sense of belongingness and participation it can inculcate
      all the constituents of the institution it is a facilitative and
      participative unit of institution it should seen as a vehicle for ushering
      in quality and excellence by working out intervention strategies to remove
      deficiencies and enhance quality. the publication of IQAC NEWS helps
      disseminating the information about the information about the activities,
      programmes, interaction carried out by an institution for sharing the same
      with the stake holders, other higher education institutions and teacher
      education institutions to be emulated and improved for achieving best
      results or for getting accredited and assessed by the NAAC.{" "}
    </p>
  </section>
  <div className="pimg2">
    <div className="ptext">
      <span className="border-x trans" >
        ABVV | IQAC
      </span>
    </div>
  </div>
  <section className="section section-dark">
    <h2>Prof. A. D. N. Bajpai</h2>
    <p>
      To attain the knowledge in real sense is the first and last goal of human
      life but it is the difficult task to define the knowledge it has so many
      approaches. Atal Bihari Vajpayee Vishwavidyalaya has accepted a new vision
      to define and identify the knowledge that skill of each and every work
      will automatically realize the sense of knowledge, it means if we do our
      prescribed work with honestly and proper skill knowledge will nationally
      take a shape and a man will find it easily. Basically, in Gita lord
      Krishna suggested Arjun as a disciple that skilful action is itself a form
      of Yoga. It means Yoga does not mean ankle exercises of body or a physical
      practice but of Yoga doing a work skilfully is also a form of Yoga.
      actually, doing a work with skill and sense of honesty is real attainment
      of knowledge which is ever pious. Skill of work contains so many elements
      in itself pure concentration and realless efforts can be counted its
      permanent components. Symbol of University is constituted to promote the
      real skill of work among the region of Chhattisgarh. And ultimate goal of
      university is to create the real skill in natives of Chhattisgarh.
      University also wants to spread this immortal message that pious knowledge
      can be attained with a practice of skilful action.
    </p>
  </section>
<Footer/>

    </div>
  );
}
);

export default Iqac;