// src/pages/DashboardPage.js
import React from 'react';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';

const DashboardPage = () => {
  return (
    <div className="d-flex" id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper" style={{width:"100%"}}>
        <Header />
        <div className="container-fluid">
          <Dashboard />
        </div>
      </div>
    </div>
  );
}
;

export default DashboardPage;
