// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const ContactUs= observer(() => {
  return (
    <div>
    <Header/>
  <section className="innerbanner" />
  <div className="w-100 d-flex">
    <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light">
      <div className="AdmissionText">
        <h4 className="AdmissionText">Admission Open 2023-24</h4>
        <br />
      </div>
      <div className="PortalDate">
        <p>
          पोर्टल खुलने की तारीख <br />- 16.06.2023
        </p>
        <p>
          पोर्टल बंद होने की तारीख <br />- 27.06.2023
        </p>
      </div>
      <button className="AdmissionLink">
        <a href="https://exam.bucgexam.in/public/home/admission">
          Click Here To Apply Online
        </a>
      </button>
    </div>
    <div className="row1 mx-auto">
      <h3 className="main-head">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
        </svg>
        Contact Us
      </h3>
      <div>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              className="form-control"
              id="message"
              placeholder="Enter your message"
              defaultValue={""}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </div>
  </div>
<Footer/>

    </div>
  );
}
);

export default ContactUs;