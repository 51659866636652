// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


import { observer } from "mobx-react";

const SSR= observer(() => {
  return (
    <div>
      <Header/>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid w-100">
      <div className="row2">
        <div className="col-auto col-md-11 col-xl-11 px-sm-12  bg-light mx-auto">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">SSR</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu1"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu1"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu2"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu2"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd "
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu3"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu3"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu4"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu4"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu5"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu5"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu6"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu6"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu7"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu7"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu8"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu8"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu9"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu9"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu10"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu10"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu11"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu11"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
              <li
                className="w-100 wd"
                style={{ boxShadow: "1px 2px 7px #7d7d7d", marginBottom: 8 }}
              >
                <a
                  href="#submenu12"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Executive Summary
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10, float: "inline-end" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu12"
                  data-bs-parent="#menu"
                >
                  <li className="w-100 wd" style={{ color: "rgb(8, 8, 139)" }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Pariatur suscipit illum, nulla nihil blanditiis tempore
                    distinctio commodi quis iure magnam laudantium provident,
                    nostrum aperiam et, nesciunt voluptates. Doloremque aliquid
                    minima possimus a facilis similique harum ipsum incidunt ut
                    fuga repellendus facere, nihil natus vitae exercitationem
                    dolor ducimus accusantium consequuntur optio ea
                    voluptatibus? Autem inventore quaerat libero dolor ad? Earum
                    aspernatur velit ex eaque quos doloribus eos! Aperiam
                    voluptatem ducimus vero nihil facere quam. Aliquid, suscipit
                    unde facilis modi mollitia placeat iusto aut rem qui eaque
                    libero, natus molestias at! Autem, itaque? Deleniti fugiat
                    similique assumenda quae, provident sit rem pariatur
                    laborum, dignissimos sequi ex et tempora unde cumque ad
                    cupiditate deserunt repellendus aliquid illo incidunt non?
                    Earum perspiciatis vitae quae iusto deleniti qui pariatur,
                    excepturi saepe iste natus enim consequatur est ipsum
                    molestias odio reiciendis cum amet tempore veniam quos
                    explicabo quasi itaque soluta. Assumenda earum quibusdam
                    deserunt. Tempora provident odio labore laboriosam
                    necessitatibus aperiam et libero, accusantium distinctio
                    possimus iure
                  </li>
                </ul>
              </li>
            </ul>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
<Footer/>

    </div>
  );
}
);

export default SSR;