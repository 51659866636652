import { Route, Routes } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage";
import AboutAbvv from "./pages/about/about-abvv";
import AboutBilaspur from "./pages/about/about-bilaspur";
import AboutKulgeet from "./pages/about/about-kulgeet";
import Recognition from "./pages/about/recognization";
import ViceChancellorsMessage from "./pages/about/vice-chancellors-messege";
import HonbleChancellor from "./pages/administration/HonbleChancellor";
import ViceChancellor from "./pages/administration/ViceChancellor";
import FormerViceChancellor from "./pages/administration/FormerViceChancellors";
import Registrar from "./pages/administration/Registrar";
import ECMember from "./pages/administration/ECMembers";
import ECNotification from "./pages/administration/ECNotification";
import ACMember from "./pages/administration/ACMember";
import ACNotification from "./pages/administration/ACNotification";
import DirectorPhysicalEducation from "./pages/administration/DirectorPhysicalEducation.jsx";
import ControllerOfExamination from "./pages/administration/ControllerOfExamination.jsx";
import FinanceOfficer from "./pages/administration/FinanceOfficer.jsx";
import Deputyregistrar from "./pages/administration/DeputyRegistrar.jsx";
import AssistantRegistrar from "./pages/administration/AssistantRegistrar.jsx";
import DeanOfStudentsWelfare from "./pages/administration/DeanOfStudentsWelfare.jsx";
import FCMember from "./pages/administration/FCMember.jsx";
import FCNotification from "./pages/administration/FCNotification.jsx";
import DOSMember from "./pages/administration/DOSMember.jsx";
import DOSNotification from "./pages/administration/DOSNotification.jsx";
import BOSMember from "./pages/administration/BOSMember.jsx";
import BOSNotification from "./pages/administration/BOSNotification.jsx";
import DCTrainingcell from "./pages/administration/DCTrainingcell.jsx";

import Iqac from "./pages/iqac/iqac";
import OnlineServices from "./pages/onlineServices/OnlineServices";
import ContactUs from "./pages/contactUs/ContactUs";
import CommitteeCell from "./pages/CommitteeCell/CommitteeCell";
import VacanciesOfUniversity from "./pages/recruiment/VacanciesOfUniversity";
import VacanciesOfColleges from "./pages/recruiment/VacanciesOfColleges";
import Department from "./pages/department/Department";
import PhotoGallary from "./pages/media/PhotoGallary";
import VideoGallary from "./pages/media/VideoGallary";
import UniversityInNews from "./pages/media/UniversityInNews";
import UniversityNewsLetter from "./pages/media/UniversityNewsLetter";
import AnnualReport from "./pages/media/AnnualReport";
import AdminLogin from "./pages/admin/AdminLogin.jsx";
import DashboardPage from "./pages/admin/DashboardPage.jsx";
// import DeanstudentWelfare from "./pages/about/StudentConer/DeanstudentWelfare.jsx";
import AcademicCalender from "./pages/StudentCorner/AcademicCalender.jsx";
import Course from "./pages/StudentCorner/Course.jsx";
import CentralLibrary from "./pages/StudentCorner/CentralLibrary.jsx";
import PraveshMargdarshika from "./pages/acadenics/PraveshMargdarshika.jsx";
import AffiliatedColleges from "./pages/acadenics/AffiliatedColleges.jsx";
import CollegeWithSubject from "./pages/acadenics/CollegeWithSubject.jsx";
import Seniority from "./pages/acadenics/seniority.jsx";
import RPIUniversity from "./pages/acadenics/RPIUniversity.jsx";
import RFIUniversity from "./pages/acadenics/RFIUniversity.jsx";
import ResearchFellowship from "./pages/acadenics/ResearchFellowship.jsx";
import StartupAtal from "./pages/acadenics/StartupAtal.jsx";
import MoUs from "./pages/acadenics/MoUs.jsx";
import Awards from "./pages/acadenics/Awards.jsx";
import Ordinance from "./pages/acadenics/Ordinance.jsx";
import Regulation from "./pages/acadenics/regulation.jsx";
import Guidelines from "./pages/acadenics/Guidelines.jsx";
import DeanStudentWelfare from "./pages/StudentCorner/DeanStudentWelfare.jsx";
import SSR from "./pages/CommitteeCell/SSR.jsx";
import PagesList from "./pages/admin/pages/PagesList.jsx";
import DownloadNav from "./pages/other/DownloadNav.jsx";
import TimeTable from "./pages/other/TimeTable.jsx";
import Result from "./pages/other/Result.jsx";
import SyllabusNotification from "./pages/other/SyllabusNotification.jsx";
import AllNotification from "./pages/other/AllNotification.jsx";


const BaseRouter = () => {
  return (
    <div>
      <Routes>
        {/* New website */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/AboutAbvv" element={<AboutAbvv />} />
        <Route exact path="/AboutBilaspur" element={<AboutBilaspur />} />
        <Route exact path="/AboutKulgeet" element={<AboutKulgeet />} />
        <Route exact path="/Recognition" element={<Recognition />} />
        <Route exact path="/ViceChancellorsMessage" element={<ViceChancellorsMessage />} />
        <Route exact path="/HonbleChancellor" element={<HonbleChancellor />} />


        {/* 
        <Route exact path='/profile' element={<ProfilePage />} />
        <Route exact path='/change-password' element={<ChangePasswordPage />} />
        <Route exact path='/preferences' element={<UserPreferencesPage />} />
        <Route exact path='/typography' element={<TypographyPage />} />
        <Route exact path='/blank-page' element={<AdminBlankPage />} /> */}

        
        <Route exact path="/PhotoGallary" element={<PhotoGallary />} />
        <Route exact path="/VideoGallary" element={<VideoGallary />} />
        <Route exact path="/AnnualReport" element={<AnnualReport />} />
        <Route exact path="/UniversityNewsLetter" element={<UniversityNewsLetter />} />
        <Route exact path="/UniversityInNews" element={<UniversityInNews />} />
        <Route exact path="/Department" element={<Department />} />
        <Route exact path="/VacanciesOfColleges" element={<VacanciesOfColleges />} />
        <Route exact path="/VacanciesOfUniversity" element={<VacanciesOfUniversity />} />
        <Route exact path="/CommitteeCell" element={<CommitteeCell />} />
        <Route exact path="/ContactUs" element={<ContactUs />} />
        <Route exact path="/OnlineServices" element={<OnlineServices />} />
        <Route exact path="/Iqac" element={<Iqac />} />
        <Route exact path="/AnnualReport" element={<AnnualReport />} />
        <Route exact path="/ViceChancellor" element={<ViceChancellor />} />
        <Route exact path="/FormerViceChancellor" element={<FormerViceChancellor />} />
        <Route exact path="/Registrar" element={<Registrar />} />
        <Route exact path="/ECNotification" element={<ECNotification />} />
        <Route exact path="/ECMember" element={<ECMember />} />
        <Route exact path="/ACMember" element={<ACMember />} />
        <Route exact path="/DirectorPhysicalEducation" element={<DirectorPhysicalEducation />} />
        <Route exact path="/ControllerOfExamination" element={<ControllerOfExamination/>} />
        <Route exact path="/FinanceOfficer" element={<FinanceOfficer/>} />
        <Route exact path="/Deputyregistrar" element={<Deputyregistrar/>} />
        <Route exact path="/AssistantRegistrar" element={<AssistantRegistrar/>} />
        <Route exact path="/DeanOfStudentsWelfare " element={<DeanOfStudentsWelfare />} />
        <Route exact path="/FCMember" element={<FCMember />} />
        <Route exact path="/FCNotification" element={<FCNotification />} />
        <Route exact path="/DOSMember " element={<DOSMember />} />
        <Route exact path="/DOSNotification  " element={<DOSNotification  />} />
        <Route exact path="/BOSMember " element={<BOSMember />} />
        <Route exact path="/BOSNotification " element={< BOSNotification />} />
        <Route exact path="/DCTrainingcell" element={< DCTrainingcell/>} />


        <Route exact path="/ACNotification" element={<ACNotification />} />
        <Route exact path="/DeanStudentWelfare" element={<DeanStudentWelfare />} />
        <Route exact path="/AcademicCalender" element={<AcademicCalender />} />
        <Route exact path="/Course" element={<Course/>} />
        <Route exact path="/CentralLibrary" element={<CentralLibrary/>} />
        <Route exact path="/PraveshMargdarshika" element={<PraveshMargdarshika />} />
        <Route exact path="/AffiliatedColleges" element={<AffiliatedColleges />} />
        <Route exact path="/CollegeWithSubject" element={<CollegeWithSubject />} />
        <Route exact path="/Seniority" element={<Seniority />} />
        <Route exact path="/RPIUniversity" element={<RPIUniversity />} />
        <Route exact path="/RFIUniversity" element={<RFIUniversity />} />
        <Route exact path="/ResearchFellowship" element={<ResearchFellowship />} />
        <Route exact path="/StartupAtal" element={<StartupAtal />} />
        <Route exact path="/MoUs" element={<MoUs />} /> 
        <Route exact path="/Awards" element={<Awards />} />
        <Route exact path="/Ordinance" element={<Ordinance />} />
        <Route exact path="/Regulation" element={<Regulation />} />
        <Route exact path="/Guidelines" element={<Guidelines />} />
        <Route exact path="/SSR" element={<SSR />} />
        <Route exact path="/DeanOfStudentsWelfare" element={<DeanOfStudentsWelfare />} />

        {/* Other Page link */}
        <Route exact path="/DownloadNav" element={<DownloadNav />} />
        <Route exact path="/Result" element={<Result />} />
        <Route exact path="/SyllabusNotification" element={<SyllabusNotification />} />
        <Route exact path="/TimeTable" element={<TimeTable />} />
        <Route exact path="/AllNotification" element={<AllNotification />} /> 
        

        {/* ADMIN ROUTING */}
        <Route exact path="/admin-login" element={<AdminLogin />} />
        <Route exact path="/dashboard" element={<DashboardPage />} />
        <Route exact path="/page-list" element={<PagesList />} />
      </Routes>
    </div>
  );
};

export default BaseRouter;