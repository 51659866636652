// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const AffiliatedColleges= observer(() => {
  return (
    <div>
      <Header/>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Academics</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/PraveshMargdarshika"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Pravesh Margdarshika
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AffiliatedColleges"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    Affiliated Colleges
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/CollegeWithSubject"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Colleges with Subjects and Intake Capacity
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Seniority"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Seniority List</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/RPIUniversity"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Research Projects in University
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="RFIUniversity"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Research Facilities in University
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ResearchFellowship"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Research Fellowship
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/StartupAtal"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Startup Atal University
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/MoUs"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">MoUs</span>
                </a>
              </li>
              <li className="w-100">
                <a href="" className="nav-link px-0 align-middle">
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">Shodhpeeth</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Awards"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Awards and Honours
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu2"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle "
                >
                  <i className="fs-4 bi-bootstrap" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Ordinance/Regulation/Guidelines
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu2"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/Ordinance"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline asd">Ordinance</span> 1
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/Regulation"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline asd">Regulation</span> 2
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/Guidelines"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className=" d-sm-inline asd">Guidelines</span> 3
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            Affiliated Colleges
          </h3>
          <div className="table-content">
            <table>
              <thead>
                <tr>
                  <th scope="col">S.N.</th>
                  <th scope="col">Code</th>
                  <th scope="col">Name of College </th>
                  <th scope="col">Type </th>
                  <th scope="col">District </th>
                  <th scope="col">Type </th>
                  <th scope="col">Email ID</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> 1 </td>
                  <td> 101 </td>
                  <td>
                    {" "}
                    INSTITUTE OF ADVANCED STUDIES IN EDUCATION, BILASPUR,
                    DIST.-BILASPUR (C.G.)
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> iasebilaspur@gmail.com </td>
                </tr>
                <tr>
                  <td> 2 </td>
                  <td> 102 </td>
                  <td>
                    {" "}
                    GOVT. E. RAGVENDRA RAO P.G. SCIENCE COLLEGE,BILASPUR (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> pr.sc.college@gmail.com </td>
                </tr>
                <tr>
                  <td> 3 </td>
                  <td> 103 </td>
                  <td> GOVT. BILASA GIRLS P.G. COLLEGE, BILASPUR (C.G.) </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> GIRLS COLLEGE </td>
                  <td> " bilasagirlscollege_bilaspur@rediffmail.com" </td>
                </tr>
                <tr>
                  <td> 4 </td>
                  <td> 104 </td>
                  <td>
                    {" "}
                    GOVT. JAMUNA PRASAD VERMA P.G. ARTS &amp; COMMERCE COLLEGE,
                    BILASPUR, DIST- BILASPUR(C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gpgacc.bsp@gmail.com </td>
                </tr>
                <tr>
                  <td> 5 </td>
                  <td> 105 </td>
                  <td>
                    {" "}
                    GOVT. MATA SHABARI NAVEEN GIRLS P.G. COLLEGE, BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> GIRLS COLLEGE </td>
                  <td> gmsngc1989@gmail.com </td>
                </tr>
                <tr>
                  <td> 6 </td>
                  <td> 106 </td>
                  <td> GOVT. AGRASEN COLLEGE, BILHA, DIST.-BILASPUR (C.G.) </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> govtagrasencollegebilha89@gmail.com </td>
                </tr>
                <tr>
                  <td> 7 </td>
                  <td> 107 </td>
                  <td>
                    {" "}
                    GOVT. NIRANJAN KESHARWANI COLLEGE, KOTA, DIST.-BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gnkckota@gmail.com </td>
                </tr>
                <tr>
                  <td> 8 </td>
                  <td> 108 </td>
                  <td>
                    {" "}
                    RAJIV GANDHI GOVT. ARTS AND COMMERCE COLLEGE, LORMI
                    DIST.-MUNGELI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> rggcollegelormi@gmail.com </td>
                </tr>
                <tr>
                  <td> 9 </td>
                  <td> 109 </td>
                  <td>
                    {" "}
                    VEERANGANA RANI DURGAWATI GOVT. COLLEGE, MARWAHI,
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> govt.collegemarwahi@gmail.com </td>
                </tr>
                <tr>
                  <td> 10 </td>
                  <td> 110 </td>
                  <td>
                    {" "}
                    GOVT. PATALESHWAR COLLEGE, MASTURI, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> govtcollegemasturi@gmail.com </td>
                </tr>
                <tr>
                  <td> 11 </td>
                  <td> 111 </td>
                  <td>
                    {" "}
                    DR. JWALA PRASAD MISHRA GOVT. SCIENCE COLLEGE, MUNGELI
                    DIST.-MUNGELI (C.G.)
                  </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> jpmcollege370@gmail.com </td>
                </tr>
                <tr>
                  <td> 12 </td>
                  <td> 113 </td>
                  <td>
                    {" "}
                    VEERANGANA AWANTI BAI LODHI GOVT. COLLEGE, PATHARIA
                    DIST.-MUNGELI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> vablgcpth@gmail.com </td>
                </tr>
                <tr>
                  <td> 13 </td>
                  <td> 114 </td>
                  <td>
                    {" "}
                    DR. BHANVAR SINGH PORTE GOVT. COLLEGE, PENDRA
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> governmentcollegependra@gmail.com </td>
                </tr>
                <tr>
                  <td> 14 </td>
                  <td> 115 </td>
                  <td>
                    {" "}
                    GOVT. PHYSICAL EDUCATION TRAINING COLLEGE, PENDRA
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> ggtcp.pendranew@gmail.com </td>
                </tr>
                <tr>
                  <td> 15 </td>
                  <td> 116 </td>
                  <td>
                    {" "}
                    GOVT. PT. MADHAV RAO SAPRE COLLEGE, PENDRA-ROAD
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> pmrscollege@yahoo.in </td>
                </tr>
                <tr>
                  <td> 16 </td>
                  <td> 117 </td>
                  <td>
                    {" "}
                    GOVT. MAHAMAYA COLLEGE,RATANPUR DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gmc_ratanpur@rediffmail.com </td>
                </tr>
                <tr>
                  <td> 17 </td>
                  <td> 118 </td>
                  <td>
                    {" "}
                    GOVT. MADAN LAL SHUKLA P.G. COLLEGE, SEEPAT DIST.-BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gmlscseepat@gmail.com </td>
                </tr>
                <tr>
                  <td> 18 </td>
                  <td> 119 </td>
                  <td>
                    {" "}
                    GOVT. J.M.P. COLLEGE, TAKHATPUR DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> principalgjmptakhatpur@gmail.com </td>
                </tr>
                <tr>
                  <td> 19 </td>
                  <td> 120 </td>
                  <td> GOVT. COLLEGE, KOTRI, DIST.-MUNGELI (C.G.) </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> principalgovtcollegekotri@gmail.com </td>
                </tr>
                <tr>
                  <td> 20 </td>
                  <td> 121 </td>
                  <td>
                    {" "}
                    SANT SHIROMANI GURU RAVIDAS GOVT. COLLEGE, SARGAON,
                    DIST.-MUNGELI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> Ssgrgovtcollegesargaon@gmail.com </td>
                </tr>
                <tr>
                  <td> 21 </td>
                  <td> 122 </td>
                  <td>
                    {" "}
                    GOVT. COLLEGE, FASTERPUR (SETGANGA), DIST.-MUNGELI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> govtcollegefasterpur@yahoo.com </td>
                </tr>
                <tr>
                  <td> 22 </td>
                  <td> 123 </td>
                  <td> GOVT. NAVEEN COLLEGE, AMORA, DIST.-MUNGELI (C.G.) </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> govtcollege.amora@gmail.com </td>
                </tr>
                <tr>
                  <td> 23 </td>
                  <td> 124 </td>
                  <td> GOVT. NAVEEN COLLEGE, SAKRI, DIST.-BILASPUR (C.G.) </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> govtcollegesakri@gmail.com </td>
                </tr>
                <tr>
                  <td> 24 </td>
                  <td> 125 </td>
                  <td>
                    {" "}
                    NAVEEN GOVT. GIRLS COLLEGE, TAKHATPUR, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> GIRLS COLLEGE </td>
                  <td> 125govtgirlscollege@gmail.com </td>
                </tr>
                <tr>
                  <td> 25 </td>
                  <td> 126 </td>
                  <td>
                    {" "}
                    GOVT. NAVEEN GIRLS COLLEGE, MUNGELI, DIST.-MUNGELI (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> GIRLS COLLEGE </td>
                  <td> </td>
                </tr>
                <tr>
                  <td> 26 </td>
                  <td> 127 </td>
                  <td> NAVEEN GOVT. COLLEGE AKALTARI,DIST.-BILASPUR (C.G.) </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> govtcollegeakaltari@gmail.com </td>
                </tr>
                <tr>
                  <td> 27 </td>
                  <td> 128 </td>
                  <td>
                    {" "}
                    SWAMI ATMANAND GOVT. ENGLISH MEDIUM MODEL COLLEGE,BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> sagemmc.bsp@gmail.com </td>
                </tr>
                <tr>
                  <td> 28 </td>
                  <td> 129 </td>
                  <td>
                    {" "}
                    NAVEEN GOVT. GIRLS COLLEGE DIST.-GAURELA-PENDRA-MARWAHI
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> GIRLS COLLEGE </td>
                  <td> </td>
                </tr>
                <tr>
                  <td> 29 </td>
                  <td> 130 </td>
                  <td> NAVEEN GOVT. COLLEGE BELTARA DIST.-BILASPUR (C.G.) </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gncbeltara@gmail.com </td>
                </tr>
                <tr>
                  <td> 30 </td>
                  <td> 131 </td>
                  <td> NAVEEN GOVT. COLLEGE JARHAGAON DIST.-MUNGELI (C.G.) </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> naveencollegejarhagaon@gmail.com </td>
                </tr>
                <tr>
                  <td> 31 </td>
                  <td> 132 </td>
                  <td> NAVEEN GOVT. COLLEGE PACHPEDI DIST.-BILASPUR (C.G.) </td>
                  <td> GOVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> govtnaveencollegepachpedi@gmail.com </td>
                </tr>
                <tr>
                  <td> 32 </td>
                  <td> 201 </td>
                  <td> C.M.DUBEY POST GRADUATE COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> info@cmdpgcollege.in </td>
                </tr>
                <tr>
                  <td> 33 </td>
                  <td> 202 </td>
                  <td>
                    {" "}
                    COLLEGE OF IT &amp; APPLIED SOCIAL SCIENCE, PENDARI,
                    BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> dronacollege202@gmail.com </td>
                </tr>
                <tr>
                  <td> 34 </td>
                  <td> 204 </td>
                  <td> D.L.S. P.G. COLLEGE, SARKANDA, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> dlspgcollege@gmail.com </td>
                </tr>
                <tr>
                  <td> 35 </td>
                  <td> 205 </td>
                  <td> D.P. VIPRA COLLEGE OF EDUCATION, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> dpvipracollegeofeducation@gmail.com </td>
                </tr>
                <tr>
                  <td> 36 </td>
                  <td> 206 </td>
                  <td> D.P. VIPRA COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> dpvipracollege@gmail.com </td>
                </tr>
                <tr>
                  <td> 37 </td>
                  <td> 207 </td>
                  <td> D.P. VIPRA LAW COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> dpvlawprincipal@yahoo.com </td>
                </tr>
                <tr>
                  <td> 38 </td>
                  <td> 208 </td>
                  <td> G.T.B.COLLEGE, NEHRU NAGAR, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gtbpte@gmail.com </td>
                </tr>
                <tr>
                  <td> 39 </td>
                  <td> 209 </td>
                  <td> KAUSHALENDRA RAO LAW COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> krlawcollege@gmail.com </td>
                </tr>
                <tr>
                  <td> 40 </td>
                  <td> 210 </td>
                  <td>
                    {" "}
                    MAHAMAYA EXCELLENCY COLLEGE, NARMADA NAGAR, BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> mahamayaexcellencybsp@gmail.com </td>
                </tr>
                <tr>
                  <td> 41 </td>
                  <td> 211 </td>
                  <td>
                    {" "}
                    MAULANA AZAD SHIKSHA MAHAVIDYALAYA,SUBHASH NAGAR,GONDPARA,
                    BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> mace.sai@rdiffmail.com </td>
                </tr>
                <tr>
                  <td> 42 </td>
                  <td> 212 </td>
                  <td>
                    {" "}
                    NALINI PRABHA DEV PRASAD ROY ART AND COMMERCE COLLEGE,
                    JORAPARA SARKANDA, BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> ndrcollege.bsp@gmail.com </td>
                </tr>
                <tr>
                  <td> 43 </td>
                  <td> 213 </td>
                  <td> P.N.S. COLLEGE, RAJENDRA NAGAR, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> principal.pns.college@gmail.com </td>
                </tr>
                <tr>
                  <td> 44 </td>
                  <td> 215 </td>
                  <td> S.B.T. COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> sbtbsp@gmail.com </td>
                </tr>
                <tr>
                  <td> 45 </td>
                  <td> 216 </td>
                  <td> SHANTI NIKETAN COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> sewaindia2010@gmail.com </td>
                </tr>
                <tr>
                  <td> 46 </td>
                  <td> 217 </td>
                  <td>
                    {" "}
                    SHRI SIDDHAPITH MAHAMAYA COLLEGE OF EDUCATION, NEHRU NAGAR,
                    BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> bharatpandey83@gmail.com </td>
                </tr>
                <tr>
                  <td> 47 </td>
                  <td> 218 </td>
                  <td>
                    {" "}
                    SHUBHAM SHIKSHAN COLLEGE, SHUBHAM VIHAR, BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> ShubhamShikshan@gmail.com </td>
                </tr>
                <tr>
                  <td> 48 </td>
                  <td> 219 </td>
                  <td>
                    {" "}
                    MAMATAMAI MINIMATA SCIENCE COLLEGE, LORMI, DIST.-MUNGELI
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> mmminimatacollege12345@gmail.com </td>
                </tr>
                <tr>
                  <td> 49 </td>
                  <td> 220 </td>
                  <td>
                    {" "}
                    DR. B.S.P. AADIWASI COLLEGE, MARWAHI,
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> drbspcollegemarwahi@gmail.com </td>
                </tr>
                <tr>
                  <td> 50 </td>
                  <td> 221 </td>
                  <td> GOVT. S.N.G. COLLEGE, MUNGELI, DIST.-MUNGELI (C.G.) </td>
                  <td> GOVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> sngcollege31@gmail.com </td>
                </tr>
                <tr>
                  <td> 51 </td>
                  <td> 222 </td>
                  <td> SUKHNANDAN COLLEGE, MUNGELI, DIST.-MUNGELI (C.G.) </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> sukhnandancollegemungeli@gmail.com </td>
                </tr>
                <tr>
                  <td> 52 </td>
                  <td> 223 </td>
                  <td>
                    {" "}
                    SANT GURU GHASIDAS ARTS AND SCIENCE COLLEGE, PACHPEDI,
                    DIST.-BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> SantGuruGhasidas@Gmail.com </td>
                </tr>
                <tr>
                  <td> 53 </td>
                  <td> 224 </td>
                  <td>
                    {" "}
                    SANT GURU GHASIDAS COLLEGE OF EDUCATION MAHAVIDYALAYA,
                    PACHPEDI, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> SantGuruGhasidas@Gmail.com </td>
                </tr>
                <tr>
                  <td> 54 </td>
                  <td> 225 </td>
                  <td>
                    {" "}
                    COLLEGE OF EDUCATION, MEDUKA (PENDRA-ROAD),
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> coedu.meduka1@gmail.com </td>
                </tr>
                <tr>
                  <td> 55 </td>
                  <td> 226 </td>
                  <td> B.L.T. COLLEGE, RATANPUR, DIST.-BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> bltbsp@yahoo.in </td>
                </tr>
                <tr>
                  <td> 56 </td>
                  <td> 227 </td>
                  <td>
                    {" "}
                    MAHAMAYA TECHNICAL AND PROFESSIONAL COLLEGE, RATANPUR,
                    DIST.-BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> harishrtp@gmail.com </td>
                </tr>
                <tr>
                  <td> 57 </td>
                  <td> 230 </td>
                  <td>
                    {" "}
                    C.S.R. COLLEGE, PIPARTARAI, KOTA, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> csrcollege.piper@gmail.com </td>
                </tr>
                <tr>
                  <td> 58 </td>
                  <td> 231 </td>
                  <td>
                    {" "}
                    RAJKOSHAL VYAVSAYIK PRASHIKSHAN SANSTHAN, LAWAR (DARRIGHAT),
                    DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> rvpsbilaspur@gmail.com </td>
                </tr>
                <tr>
                  <td> 59 </td>
                  <td> 232 </td>
                  <td> J.E.S. COLLEGE, FARHADA, DIST.-BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> jesbilaspur@gmail.com </td>
                </tr>
                <tr>
                  <td> 60 </td>
                  <td> 233 </td>
                  <td>
                    {" "}
                    G.T.B. COLLEGE OF PROFESSIONAL AND TECHNICAL EDUCATION,
                    FARHADA, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gtbpte@gmail.com </td>
                </tr>
                <tr>
                  <td> 61 </td>
                  <td> 234 </td>
                  <td>
                    {" "}
                    G.T.B. COLLEGE OF EDUCATION, FARHADA, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gtbpte@gmail.com </td>
                </tr>
                <tr>
                  <td> 62 </td>
                  <td> 235 </td>
                  <td>
                    {" "}
                    PT. MADAN MOHAN MALVIYA COLLEGE OF EDUCATION, LAWAR,
                    DIST.-BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> pmmmce@gmail.com </td>
                </tr>
                <tr>
                  <td> 63 </td>
                  <td> 236 </td>
                  <td>
                    {" "}
                    AYUSH COLLEGE OF EDUCATION, MARWAHI,
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> ayush.edu12@gmail.com </td>
                </tr>
                <tr>
                  <td> 64 </td>
                  <td> 237 </td>
                  <td>
                    {" "}
                    J.E.S. COLLEGE OF EDUCATION, FARHADA, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> jesbilaspur@gmail.com </td>
                </tr>
                <tr>
                  <td> 65 </td>
                  <td> 239 </td>
                  <td>
                    {" "}
                    S.S. COLLEGE OF EDUCATION, SARBAHARA, PENDRA-ROAD,
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> shahidzaman@rediffmail.com </td>
                </tr>
                <tr>
                  <td> 66 </td>
                  <td> 240 </td>
                  <td>
                    {" "}
                    L.C.I.T. COLLEGE OF COMMERCE AND SCIENCE, BODRI, BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> lcit.cs@gmail.com </td>
                </tr>
                <tr>
                  <td> 67 </td>
                  <td> 242 </td>
                  <td>
                    {" "}
                    AYUSH COLLEGE OF EDUCATION, MEDUKA (PENDRA-ROAD),
                    DIST.-GAURELA-PENDRA-MARWAHI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> coedu.meduka1@gmail.com </td>
                </tr>
                <tr>
                  <td> 68 </td>
                  <td> 243 </td>
                  <td>
                    {" "}
                    SANDIPANI ACADEMY, PENDRI, MASTURI, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> sandipanieducation.masturi@gmail.com </td>
                </tr>
                <tr>
                  <td> 69 </td>
                  <td> 244 </td>
                  <td>
                    {" "}
                    SONKAR COLLEGE, BUNDELI, GHORPURA, MUNGELI, DIST.-MUNGELI
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> sonkarmungeli@rediffmail.com </td>
                </tr>
                <tr>
                  <td> 70 </td>
                  <td> 245 </td>
                  <td>
                    {" "}
                    I.P.S. GURUKUL COLLEGE, BELTUKARI, GANIYARI, DIST.-BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> ipsgurukul.college@gmail.com </td>
                </tr>
                <tr>
                  <td> 71 </td>
                  <td> 246 </td>
                  <td>
                    {" "}
                    SIDDHARTH COLLEGE, HARDIKALA, BILHA, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> info@siddharthcollege.co.in </td>
                </tr>
                <tr>
                  <td> 72 </td>
                  <td> 247 </td>
                  <td>
                    {" "}
                    CHOUKSEY COLLEGE OF SCIENCE AND COMMERCE, LALKHADAN,
                    BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> ccscbsp@gmail.com </td>
                </tr>
                <tr>
                  <td> 73 </td>
                  <td> 248 </td>
                  <td>
                    {" "}
                    R.D.S. COLLEGE, UMARIYA, BILHA, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> jlmahto1972@gmail.com </td>
                </tr>
                <tr>
                  <td> 74 </td>
                  <td> 249 </td>
                  <td>
                    {" "}
                    JAGRANI DEVI COLLEGE, BILASPUR, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> jrdcollege249@gmail.com </td>
                </tr>
                <tr>
                  <td> 75 </td>
                  <td> 251 </td>
                  <td>
                    {" "}
                    SARDAR BHAGAT SINGH COLLEGE, BELGAHNA, DIST- BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> sbscollegebelgahna965@gmail.com </td>
                </tr>
                <tr>
                  <td> 76 </td>
                  <td> 252 </td>
                  <td> C.M.D. POST-GRADUATE COLLEGE, BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> bedcmdprincipal@gmail.com </td>
                </tr>
                <tr>
                  <td> 77 </td>
                  <td> 253 </td>
                  <td>
                    {" "}
                    B.R.Sao Science,Arts and Commerce College Nevsa Beltara
                    DIST.-BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> Brsahutechnology@gmail.com </td>
                </tr>
                <tr>
                  <td> 78 </td>
                  <td> 254 </td>
                  <td> Vaidic Mahavidyalaya, Sipat DIST.-BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> dwarikeshpandey1963@gmail.com </td>
                </tr>
                <tr>
                  <td> 79 </td>
                  <td> 255 </td>
                  <td> USHA DEVI MEMORIAL COLLEGE SAKRI BILASPUR </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> ushadevicollege@gmail.com </td>
                </tr>
                <tr>
                  <td> 80 </td>
                  <td> 256 </td>
                  <td>
                    {" "}
                    NAYANTARA SHARMA COLLEGE,Pandhi, DIST.-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> pranjaldiwan90@yahoo.com </td>
                </tr>
                <tr>
                  <td> 81 </td>
                  <td> 257 </td>
                  <td>
                    {" "}
                    Maharana Pratap Mahavidyalaya,Uslapur Sakri, DIST.-BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> mpm257@gmail.com </td>
                </tr>
                <tr>
                  <td> 82 </td>
                  <td> 258 </td>
                  <td>
                    {" "}
                    DURGA KANYA MAHAVIDYALAYA,KHAMHARIYA-MADAI,DIST.-BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> GIRLS COLLEGE </td>
                  <td> dkm.mk2022@gmail.com </td>
                </tr>
                <tr>
                  <td> 83 </td>
                  <td> 259 </td>
                  <td>
                    {" "}
                    RANI PADMAVATI MAHAVIDYALAYA,LORMI, DIST.-MUNGELI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> ranipadmavaticlglormi@gmail.com </td>
                </tr>
                <tr>
                  <td> 84 </td>
                  <td> 260 </td>
                  <td>
                    {" "}
                    J.K. EDUCATIONAL INSTITUTE OF SCIENCE ARTS AND COMMERCE
                    COLLEGE, GATORA,KARRA, DIST.-BILASPUR (C.G.)
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> principal.jkiasc@gmail.com </td>
                </tr>
                <tr>
                  <td> 85 </td>
                  <td> 261 </td>
                  <td>
                    {" "}
                    SHRI SIDDHIVINAYAK MAHAVIDYALAYA PARASWARA LORMI
                    DIST.-MUNGELI (C.G.{" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> mukeshmaravi230@gmail.com </td>
                </tr>
                <tr>
                  <td> 86 </td>
                  <td> 262 </td>
                  <td>
                    {" "}
                    Virangana Rani Durgawati Mahavidyalaya Sekhwa (Kotmikala)
                    DIST.-Gaurela-Pendra-Marwahi (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> Ranidurgawati84618900@gmail.com </td>
                </tr>
                <tr>
                  <td> 87 </td>
                  <td> 263 </td>
                  <td>
                    {" "}
                    Saraswati Mahavidyalaya,Sendri,Koni, Bilaspur DIST.-BILASPUR
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Gaurela-Pendra-Marwahi </td>
                  <td> COED COLLEGE </td>
                  <td> mahavidyalaysaraswati@gmail.com </td>
                </tr>
                <tr>
                  <td> 88 </td>
                  <td> 264 </td>
                  <td>
                    {" "}
                    TRIMURTY PROFESSIONAL COLLEGE MOHABATTA, PATHARIA
                    ROAD,SARGAON DIST. MUNGELI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> tps.sargaon@gmail.com </td>
                </tr>
                <tr>
                  <td> 89 </td>
                  <td> 265 </td>
                  <td>
                    {" "}
                    Sevak Ram Sahu College, Fulwari DIST. MUNGELI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> ssrs202324@gmail.com </td>
                </tr>
                <tr>
                  <td> 90 </td>
                  <td> 266 </td>
                  <td>
                    {" "}
                    VISHNUKANTI COLLEGE CHHITAPAR LORMI DIST. MUNGELI (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> Vkcollege77@gmail.com </td>
                </tr>
                <tr>
                  <td> 91 </td>
                  <td> 267 </td>
                  <td> GURUKRIPA COLLEGE NEHRU CHOUK,BILASPUR (C.G.) </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> gurukripacollegeb@gmail.com </td>
                </tr>
                <tr>
                  <td> 92 </td>
                  <td> 268 </td>
                  <td> SANSKAR COLLEGE SAKET PATHARIYA DIST.-MUNGELI </td>
                  <td> PVT </td>
                  <td> Mungeli </td>
                  <td> COED COLLEGE </td>
                  <td> harishrtp@gmail.com </td>
                </tr>
                <tr>
                  <td> 93 </td>
                  <td> 269 </td>
                  <td>
                    {" "}
                    VIDYA SAGAR COLLEGE BARIDIH,RATANPUR DIST-BILASPUR (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Bilaspur </td>
                  <td> COED COLLEGE </td>
                  <td> vidyasagarmahavidyalaya2023@gmail.com </td>
                </tr>
                <tr>
                  <td> 94 </td>
                  <td> 501 </td>
                  <td>
                    {" "}
                    SWA. PYARELAL KANWAR GOVT. COLLEGE, BHAISMA, DIST.-KORBA
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> bhaisma.pgc@gmail.com </td>
                </tr>
                <tr>
                  <td> 95 </td>
                  <td> 502 </td>
                  <td> GOVT. COLLEGE, DIPKA, DIST.-KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> deepkacollege@gmail.com </td>
                </tr>
                <tr>
                  <td> 96 </td>
                  <td> 503 </td>
                  <td> GOVT. COLLEGE, KARTALA, DIST.-KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> gckkartala0538028@gmail.com </td>
                </tr>
                <tr>
                  <td> 97 </td>
                  <td> 504 </td>
                  <td>
                    {" "}
                    GOVT. MUKUTDHAR PANDEY COLLEGE, KATGHORA, DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> mdpcollegektg@gmail.com </td>
                </tr>
                <tr>
                  <td> 98 </td>
                  <td> 505 </td>
                  <td> GOVT. E. V. POST-GRADUATE COLLEGE, KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> gevpg1981@gmail.com </td>
                </tr>
                <tr>
                  <td> 99 </td>
                  <td> 506 </td>
                  <td>
                    {" "}
                    GOVT. MINIMATA GIRLS COLLEGE, KORBA, DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> GIRLS COLLEGE </td>
                  <td> minimata89@rediffmail.com </td>
                </tr>
                <tr>
                  <td> 100 </td>
                  <td> 507 </td>
                  <td> GOVT. COLLEGE, BARPALI, DIST.-KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> govtcollegebarpali@yahoo.com </td>
                </tr>
                <tr>
                  <td> 101 </td>
                  <td> 508 </td>
                  <td> GOVT. NAVEEN COLLEGE, PALI, DIST.-KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> ugcollegepali@gmail.com </td>
                </tr>
                <tr>
                  <td> 102 </td>
                  <td> 509 </td>
                  <td>
                    {" "}
                    GOVT. GRAMYA BHARTI COLLEGE, HARDIBAZAR, DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> gbvphardibazar@gmail.com </td>
                </tr>
                <tr>
                  <td> 103 </td>
                  <td> 510 </td>
                  <td> GOVT. NAVEEN COLLEGE, JATGA, DIST. KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> 510govtcollege.jatga@gmail.com </td>
                </tr>
                <tr>
                  <td> 104 </td>
                  <td> 511 </td>
                  <td>
                    {" "}
                    GOVT. NAVEEN COLLEGE, Banki Mongra, DIST. KORBA (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> gncbankimongra@gmail.com </td>
                </tr>
                <tr>
                  <td> 105 </td>
                  <td> 512 </td>
                  <td>
                    {" "}
                    NAVEEN GOVT. ADARSH COLLEGE, KORBA , DIST. KORBA (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> " dilipkumarsahu1964@gmail.com" </td>
                </tr>
                <tr>
                  <td> 106 </td>
                  <td> 513 </td>
                  <td>
                    {" "}
                    SWAMI ATMANAND GOVT. ENGLISH MEDIUM MODEL COLLEGE,KORBA
                    (C.G.){" "}
                  </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> ganmckrb@gmail.com </td>
                </tr>
                <tr>
                  <td> 107 </td>
                  <td> 514 </td>
                  <td> NAVEEN GOVT. COLLEGE UMRELI,DISTT.-KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> principalumreli@gmail.com </td>
                </tr>
                <tr>
                  <td> 108 </td>
                  <td> 515 </td>
                  <td> NAVEEN GOVT.COLLEGE RAMPUR,DISTT.-KORBA (C.G.) </td>
                  <td> GOVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> ngcrampur@gmail.com </td>
                </tr>
                <tr>
                  <td> 109 </td>
                  <td> 603 </td>
                  <td>
                    {" "}
                    JAI BUDHADEV ARTS AND SCIENCE COLLEGE, KATGHORA, DIST.-KORBA
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> jbdkatghora@gmail.com </td>
                </tr>
                <tr>
                  <td> 110 </td>
                  <td> 604 </td>
                  <td>
                    {" "}
                    SHRI AGRASEN GIRLS COLLEGE, KORBA, DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> GIRLS COLLEGE </td>
                  <td> agrasencollege1999@gmail.com </td>
                </tr>
                <tr>
                  <td> 111 </td>
                  <td> 605 </td>
                  <td>
                    {" "}
                    JYOTI BHUSHAN PRATAP SINGH LAW COLLEGE, KORBA, DIST.-KORBA
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> principal@jbpslawcollege.ac.in </td>
                </tr>
                <tr>
                  <td> 112 </td>
                  <td> 606 </td>
                  <td>
                    {" "}
                    KAMLA NEHRU MAHAVIDYALAYA, KORBA, DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> info@knc-ac.in </td>
                </tr>
                <tr>
                  <td> 113 </td>
                  <td> 608 </td>
                  <td> KORBA COMPUTER COLLEGE, KORBA, DIST.-KORBA (C.G.) </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> korbacomputer@yahoo.co.in </td>
                </tr>
                <tr>
                  <td> 114 </td>
                  <td> 609 </td>
                  <td>
                    {" "}
                    MODERN COLLEGE OF MANAGEMENT AND INFORMATION TECHNOLOGY,
                    KORBA, DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> mcmit@moderneducationgroup.in </td>
                </tr>
                <tr>
                  <td> 115 </td>
                  <td> 610 </td>
                  <td>
                    {" "}
                    MATA KARMA ARTS AND COMMERCE COLLEGE, KUSMUNDA, DIST.-KORBA
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> matakarma2012@gmail.com </td>
                </tr>
                <tr>
                  <td> 116 </td>
                  <td> 611 </td>
                  <td> BISAHUDAS MAHANT COLLEGE, PALI, DIST-KORBA (C.G.) </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> bdmclgpali@gmail.com </td>
                </tr>
                <tr>
                  <td> 117 </td>
                  <td> 612 </td>
                  <td> HASDEV EDUCATION COLLEGE, KORBA, DIST.-KORBA (C.G.) </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> deskorba@gmail.com </td>
                </tr>
                <tr>
                  <td> 118 </td>
                  <td> 613 </td>
                  <td>
                    {" "}
                    HASDEV EDUCATION COLLEGE, AAMAPALI, TILKEJA, KORBA,
                    DIST.-KORBA (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> deskorba@gmail.com </td>
                </tr>
                <tr>
                  <td> 119 </td>
                  <td> 614 </td>
                  <td>
                    {" "}
                    JIFSA Professional College,Kuchaina KORBA, DIST.-KORBA
                    (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> admin@jifsacollege.in </td>
                </tr>
                <tr>
                  <td> 120 </td>
                  <td> 615 </td>
                  <td>
                    {" "}
                    AK GURUKUL COLLEGE DHELWADIH,KORBA DIST.- KORBA (C.G.){" "}
                  </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> akgurukulkorba3655@gmail.com </td>
                </tr>
                <tr>
                  <td> 121 </td>
                  <td> 616 </td>
                  <td> ORIENTAL COLLEGE MANIKPUR,DIST.-KORBA (C.G.) </td>
                  <td> PVT </td>
                  <td> Korba </td>
                  <td> COED COLLEGE </td>
                  <td> oricolkrb2023@gmail.com </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
<Footer/>

    </div>
  );
}
);

export default AffiliatedColleges;