// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const VideoGallary= observer(() => {
  return (
    <div>
      <Header/>
       <section class="innerbanner"></section>
  <div class="container-fluid body-content content" id="main-content">





<div class="row minusmrgin">

<div class="col-md-12">
  <div class="boxed min700">

      <h3 class="text-primary-emphasis">
          <i class="fa-solid fa-calendar-check"></i> video_gallery
      </h3>
<form action="/home/eventlist" class="" method="get"><div class="container">
              <table class="table table-warning">
                  <tr>
                      <td>
                          <div class="form-group">
                              <label for="pageSize">Display Per Page</label>
                              <input data-val="true" data-val-number="The field Int32 must be a number." data-val-required="The Int32 field is required." id="deptid" name="deptid" type="hidden" value="0" />
                              <select class="form-control" id="pagesize" name="pagesize"><option value="4">4</option>
<option value="8">8</option>
<option selected="selected" value="12">12</option>
<option value="16">16</option>
<option value="20">20</option>
<option value="24">24</option>
<option value="28">28</option>
<option value="32">32</option>
<option value="36">36</option>
<option value="40">40</option>
<option value="44">44</option>
<option value="48">48</option>
<option value="52">52</option>
<option value="56">56</option>
<option value="60">60</option>
<option value="64">64</option>
<option value="68">68</option>
<option value="72">72</option>
<option value="76">76</option>
<option value="80">80</option>
<option value="84">84</option>
<option value="88">88</option>
<option value="92">92</option>
<option value="96">96</option>
<option value="100">100</option>
</select>
                          </div>
                      </td>
                      <td>
                          <div class="form-group">
                              <label for="name">Subject</label>
                              <input class="form-control" id="name" name="name" placeholder="Subject" type="text" value="" />
                          </div>
                      </td>
                      <td>
                          <div class="form-group">
                              <label for="month">Month</label>
                              <select class="form-control" id="month" name="month"><option selected="selected" value="0">All</option>
<option value="1">January</option>
<option value="2">February</option>
<option value="3">March</option>
<option value="4">April</option>
<option value="5">May</option>
<option value="6">June</option>
<option value="7">July</option>
<option value="8">August</option>
<option value="9">September</option>
<option value="10">October</option>
<option value="11">November</option>
<option value="12">December</option>
</select>
                          </div>
                      </td>
                      <td>
                          <div class="form-group">
                              <label for="year">Year</label>
                              <select class="form-control" id="year" name="year"><option selected="selected" value="0">All</option>
<option value="2019">2019</option>
<option value="2020">2020</option>
<option value="2021">2021</option>
<option value="2022">2022</option>
<option value="2023">2023</option>
<option value="2024">2024</option>
</select>
                          </div>
                      </td>

                      <td>
                          <div class="form-group">
                              <label for="search">  </label>
                              <button type="submit" class="btn btn-success form-control">Search!</button>
                          </div>
                      </td>
                  </tr>
              </table>
          </div>
</form>            <div class="container">
          <div class="row">
                  <div class="alert alert-success" role="alert">
                      <b>Sorry!</b>
                      Noting Found! We haven't found any data you requested.
                  </div>
          </div>

      </div>
  </div>
</div>
</div>
  </div>
      <Footer/>
    </div>
  );
}
);

export default VideoGallary;