// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const OnlineServices= observer(() => {
  return (
    <div>
        <Header/>
 
  <section className="innerbanner" />
  <div className="container-fluid body-content content" id="main-content">
    <div className="row3 minusmrgin">
      <div className="col-md-12">
        <div className="boxed min700">
          <h3 className="text-primary-emphasis">
            <i className="fa-solid fa-list-check" /> Online Services
          </h3>
          <form action="/n/online-services" className="" method="get">
            <table className="table table-warning">
              <tbody>
                <tr>
                  <td>
                    <div className="form-group">
                      <label htmlFor="pageSize">Display Per Page</label>
                      <select
                        className="form-control"
                        id="pagesize"
                        name="pagesize"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option selected="selected" value={20}>
                          20
                        </option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                        <option value={40}>40</option>
                        <option value={45}>45</option>
                        <option value={50}>50</option>
                        <option value={55}>55</option>
                        <option value={60}>60</option>
                        <option value={65}>65</option>
                        <option value={70}>70</option>
                        <option value={75}>75</option>
                        <option value={80}>80</option>
                        <option value={85}>85</option>
                        <option value={90}>90</option>
                        <option value={95}>95</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="name">Subject</label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Subject"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="month">Month</label>
                      <select className="form-control" id="month" name="month">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="year">Year</label>
                      <select className="form-control" id="year" name="year">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={2019}>2019</option>
                        <option value={2020}>2020</option>
                        <option value={2021}>2021</option>
                        <option value={2022}>2022</option>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="search"></label>
                      <button
                        type="submit"
                        className="btn btn-success form-control"
                      >
                        Search!
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <table className="table table2 table-hover table-striped table-bordered ">
            <thead>
              <tr className="table-warning">
                <th>S No.</th>
                <th>Subject</th>
                <th>Notice Date</th>
                <th>View/Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/public/home/search_rvrt_result"
                    className=""
                    target="_blank"
                  >
                    <b>Revaluation/Retotling Result</b>
                  </a>
                </td>
                <td>
                  <b>10-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/public/home/search_rvrt_result"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Application form for Migration Certificate</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Application form for Provisional</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Application form for Duplicate Marksheet</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Eligibility</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Teacher's Information System</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online College &amp; Teacher Information</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Result</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Revaluation Retotaling Result</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Admit Card</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>
                      Online Examination Form Annual
                      (Private/EX/Suppl.)/Semester (ATKT)
                    </b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Attestation Sheet</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>13</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Question Paper without Login</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>14</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online GAP Registration Exam Form</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Available Seats for College Wise Exam Form</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>16</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Examination Wise Exam Form</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>17</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Student Login</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>18</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b> College Login</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>19</td>
                <td>
                  <a
                    href="https://accounts.digitallocker.gov.in/signup"
                    className=""
                    target="_blank"
                  >
                    <b>Digilocker Login </b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://accounts.digitallocker.gov.in/signup"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>20</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className=""
                    target="_blank"
                  >
                    <b>Online Examination Forms</b>
                  </a>
                </td>
                <td>
                  <b>09-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className="col-sm-10">
            <div className="center">
              <div className="pagination-container">
                <ul className="pagination">
                  <li className="active">
                    <a>1</a>
                  </li>
                  <li>
                    <a href="/n/online-services?page=2&pagesize=20&year=0&month=0">
                      2
                    </a>
                  </li>
                  <li className="PagedList-skipToNext">
                    <a
                      href="/n/online-services?page=2&pagesize=20&year=0&month=0"
                      rel="next"
                    >
                      »
                    </a>
                  </li>
                </ul>
              </div>
              Page 1 of 2
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<Footer/>
    </div>
  );
}
);

export default OnlineServices;