import React from 'react';

import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ width: '300px', height: '100vh' }}>
      <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span className="fs-4 "><img src="image/ABVV-NewLogo.png" width="30px "alt="" />ABVV | Admin</span> 
      </a>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        
        <li>
          <NavLink to="/products" className="nav-link text-dark">
            <i className="bi bi-box"></i>
            Pages
          </NavLink>
        </li>
        <li>
          <NavLink to="/customers" className="nav-link text-dark">
            <i className="bi bi-people"></i>
            Customers
          </NavLink>
        </li>
      </ul>
      <hr />
      <div className="dropdown">
        <a href="#" className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="https://via.placeholder.com/40" alt="" width="32" height="32" className="rounded-circle me-2"/>
          <strong>mdo</strong>
        </a>
        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
          <li><a className="dropdown-item" href="#">Profile</a></li>
          <li><a className="dropdown-item" href="#">Settings</a></li>
          <li><a className="dropdown-item" href="#">Sign out</a></li>
        </ul>
      </div>
    </div>
  );
}
;

export default Sidebar;
