// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const Ordinance= observer(() => {
  return (
    <div>
      <Header/>
      <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="width100">
      <div className="container-fluid">
        <div className="row2">
          <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
            <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
              <h3 className="main-head">Academics</h3>
              <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                id="menu"
              >
                <li className="w-100">
                  <a
                    href="../../Academics/pravesh-margdarshika/pravesh.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Pravesh Margdarshika
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Affiliated_colleges/affiliated-colleges.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Affiliated Colleges
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/colleges-with-subject-and-intake-capacity/colleges-with.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Colleges with Subjects and Intake Capacity
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Seniority-list/seniority.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Seniority List
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Research-Projects-in-University/Research-Projects-in-University.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Research Projects in University
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Research-Facilities-in-University/Research-Facilities.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Research Facilities in University
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Research-Fellowship/Research-Fellowship.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Research Fellowship
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Startup-Atal-University/Startup-Atal-University.html"
                    className="nav-link align-middle px-0"
                  >
                    <i className="fs-4 bi-house" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Startup Atal University
                    </span>
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/MoUs/mous.html"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline asd">MoUs</span>
                  </a>
                </li>
                <li className="w-100">
                  <a href="" className="nav-link px-0 align-middle">
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline asd">Shodhpeeth</span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="../../Academics/Awards_and_Honours/awards-and-honours.html"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fs-4 bi-table" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Awards and Honours
                    </span>{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#submenu2"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle "
                  >
                    <i className="fs-4 bi-bootstrap" />{" "}
                    <span className="ms-1  d-sm-inline asd">
                      Ordinance/Regulation/Guidelines
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <svg
                      style={{ width: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </a>
                  <ul
                    className="collapse nav flex-column ms-1"
                    id="submenu2"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <a
                        href="../../Academics/Ordinance_Regulation_Guidelines/ordinance.html"
                        className="nav-link px-0"
                        style={{
                          borderLeft: "4px solid #03356E",
                          backgroundColor: "#03356E",
                          pointerEvents: "none"
                        }}
                      >
                        {" "}
                        <span
                          className=" d-sm-inline asd"
                          style={{ color: "#fff" }}
                        >
                          Ordinance
                        </span>{" "}
                      
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="../../Academics/Ordinance_Regulation_Guidelines/regulation.html"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline asd">Regulation</span> 2
                      </a>
                    </li>
                    <li className="w-100">
                      <a
                        href="../../Academics/Ordinance_Regulation_Guidelines/guidelines.html"
                        className="nav-link px-0"
                      >
                        {" "}
                        <span className=" d-sm-inline asd">Guidelines</span> 3
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <hr />
            </div>
          </div>
          <div className="row1 mx-auto">
            <h3 className="main-head">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
              </svg>
              Ordinance
            </h3>
            <form>
              <div className="form form1">
                <div>
                  <label htmlFor="inlineFormInput">Display Per Page</label>
                  <select className="form-control">
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option selected="selected" value={20}>
                      20
                    </option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={35}>35</option>
                    <option value={40}>40</option>
                    <option value={45}>45</option>
                    <option value={50}>50</option>
                    <option value={55}>55</option>
                    <option value={60}>60</option>
                    <option value={65}>65</option>
                    <option value={70}>70</option>
                    <option value={75}>75</option>
                    <option value={80}>80</option>
                    <option value={85}>85</option>
                    <option value={90}>90</option>
                    <option value={95}>95</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="inlineFormInput">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="Subject"
                  />
                </div>
                <div>
                  <label htmlFor="inlineFormInput">Month</label>
                  <select className="form-control">
                    <option value={1}>January</option>
                    <option value={2}>February</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="inlineFormInput">Year</label>
                  <select className="form-control">
                    <option value={2019}>2019</option>
                    <option value={2020}>2020</option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </select>
                </div>
                <div>
                  <button type="submit" className="btn btn-success">
                    Search!
                  </button>
                </div>
              </div>
            </form>
            <table className="table table-hover table-striped table2">
              <thead>
                <tr className="table-warning">
                  <th>S No.</th>
                  <th>Subject</th>
                  <th>Notice Date</th>
                  <th>View/Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/Ordinance-144%20singed%2010-01-2023_2023216123806.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Revised Ordinance 144-Four Year Under Graduate Program
                        (FYUP) with multiple Entry and Exit under Choice Based
                        Credit System (CBCS) and Learning Outcome Based
                        Curriculum Framework (LOCF) For Autonomous Colleges
                        affiliated to Atal Bihari Vishwavidyalaya Bilaspur.
                      </b>
                    </a>
                  </td>
                  <td>
                    <b> 10-Jan-2023 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/Ordinance-144%20singed%2010-01-2023_2023216123806.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance158_2023216122646.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Ordinance No. 158 For B.Com (Honors) Under Choice Based
                        Credit System (CBCS)
                      </b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance158_2023216122646.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance157_2023216122918.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Ordinance No. 157 For B.Sc. (Honors) Under Choice Based
                        Credit System (CBCS)
                      </b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance157_2023216122918.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance155_2023216123026.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Ordinance 155 - Master of Computer Application (MCA)
                        Under Choice Based Credit System (CBCS)
                      </b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance155_2023216123026.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance148_2023216123201.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Ordinance 148 - Two years PG Semester Course in College
                      </b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance148_2023216123201.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance143_2023216123243.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Ordinance 143 - Bachelor of Hotel Management (BHM) in
                        UTD
                      </b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance143_2023216123243.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance142_2023216123443.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>
                        Ordinance 142 - Two years Masters Degree Programmes in
                        UTD
                      </b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance142_2023216123443.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance42_2023216123659.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>Ordinance 42 - Ph.D.</b>
                    </a>
                  </td>
                  <td>
                    <b>02-Feb-2021 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/odinance42_2023216123659.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/Ordinance%201%20Student%20Union_2023216122414.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>Ordinance 1 for the students union.</b>
                    </a>
                  </td>
                  <td>
                    <b>13-Aug-2014 </b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/Ordinance%201%20Student%20Union_2023216122414.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/ORDINANCE%202%20Student%20Union_2023216122451.pdf"
                      className=""
                      target="_blank"
                    >
                      <b>Ordinance 2 for the students union.</b>
                    </a>
                  </td>
                  <td>
                    <b>13-Aug-2014</b>
                  </td>
                  <td>
                    <a
                      href="https://abvv.ac.in/Uploads/ORDINANCE%202%20Student%20Union_2023216122451.pdf"
                      className="btn btn-success"
                      target="_blank"
                      style={{
                        width: 42,
                        height: 38,
                        padding: "6px 12px",
                        textAlign: "center"
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="16px"
                        fill="white"
                      >
                        {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                        <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
                      </svg>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

      <Footer/>
    </div>
  );
}
);

export default Ordinance;