// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const CommitteeCell= observer(() => {
  return (
    <div>
        <Header/>

        <>
  <section className="innerbanner" />
  <div style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
            <h3 className="main-head">Career Counseling And Placement</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="../About_Abvv/about-abvv.html"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    Career Counseling And Placement
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../About_Bilaspur/about-bilaspur.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Students Grievance Redressal
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Vice_Chancellors/vice-chancellors-messsage.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Academic Activity
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../About_Kulgeet/abvv-kulgeet.html"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Atal Bihari Vajpayee Vyakhyan Mala
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Research Project</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Conference and Seminar
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">IAEDM</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Skill Development and Entrepreneurship
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Anti-Ragging</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Cultural Committee
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Psychological Advisory
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Media Cell</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Research Promotion
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Internal Complain Committee
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Alumni Association
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Gender Sensitization and Women Welfare
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Intellectual Property Rights
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Equal Opportunity cell
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">RTI Cell</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Minority Cell</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">ST/SC/OBC Cell</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
            Career Counseling And Placement
          </h3>
          <div className="sidesection2 w-100">
            <div className="img1" style={{ width: "65%" }}>
              <img
                style={{ width: "100%" }}
                src="image/Academic Activity cell.jpeg"
                alt=""
              />
            </div>
            <div style={{ width: "35%" }} className="notification">
              <div className="panel panel-default boxed">
                <div className="panel-heading">
                  <h3 className="text-primary-emphasis text-center d-flex justify-content-center gap-2">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="24.5px"
                      fill="#03356E"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                    </svg>{" "}
                    Notifications
                  </h3>
                </div>
                <div className="panel-body p-2">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="news1 list-group">
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 14-Jan-2024</b>{" "}
                          </span>
                          <a href="/n/NAAC" className="">
                            <b>testing</b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 04-Oct-2023</b>{" "}
                          </span>
                          <a href="/n/vacancies-in-university" className="">
                            <b>
                              प्रोग्रामर, कुलपति के निज सहायक, स्टेनोेटाइपिस्ट
                              पद की लिखित परीक्षा संबंधी सूचना।
                            </b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 03-Oct-2023</b>{" "}
                          </span>
                          <a href="/n/phd" className="">
                            <b>
                              प्री. पी-एच.डी. प्रवेश परीक्षा DET 2023 -
                              (Doctoral Entrance Test) के लिए आवेदन करने की तिथि
                              में वृद्धि के संबंध में |
                            </b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 26-May-2023</b>{" "}
                          </span>
                          <a href="/n/department-notifications" className="">
                            <b>Merit List 2021-22</b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 26-May-2023</b>{" "}
                          </span>
                          <a href="/n/download" className="">
                            <b>Application Form of Merit Scholarship</b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 26-May-2023</b>{" "}
                          </span>
                          <a href="/n/download" className="">
                            <b>No Dues Form</b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 24-May-2023</b>{" "}
                          </span>
                          <a href="/n/download" className="">
                            <b>
                              Fee Details Academic Session 2021-2022 (Odd
                              Semester )
                            </b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 24-May-2023</b>{" "}
                          </span>
                          <a href="/n/college-result" className="">
                            <b>
                              Result : (912) LL.B. PART TWO (I SEM.) (NEW
                              COURSE)
                            </b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 24-May-2023</b>{" "}
                          </span>
                          <a href="/n/notifications" className="">
                            <b>
                              नवाचारों को प्रोत्साहन के उद्देश्य से आर्थिक
                              सहायता हेतु आवेदनों का आमंत्रण |
                            </b>
                          </a>
                        </li>
                        <li className="news-item">
                          <span className="text-warning">
                            <b> 15-Sep-2021</b>{" "}
                          </span>
                          <a href="/n/recognition" className="">
                            <b>Approval of 12B Status by UGC.</b>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="panel-footer my-3">
                  <a className="btn btn-infobtn btn-info" role="button" href="">
                    View All Notices
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>



      <Footer/>
    </div>
  );
}
);

export default CommitteeCell;