// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const AcademicCalender= observer(() => {
  return (
    <div>
        <Header/>
        <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Students Corner</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/DeanStudentWelfare"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    Dean Student's Welfare
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AcademicCalender"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline asd"
                    style={{ color: "#fff" }}
                  >
                    Academic Calender
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Course"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Course</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Admission_notification/Admission_notifaction.html"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    Admission Notification
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    Latest Notification
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    College Notification
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    National Service Scheme
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu2"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle "
                >
                  <i className="fs-4 bi-bootstrap" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    UTD National Service Scheme
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu2"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a href="" className="nav-link px-0">
                      {" "}
                      <span className=" d-sm-inline asd">
                        UTD NSS Notification/NewsLetter/Report
                      </span>{" "}
                      1
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Download</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/CentralLibrary"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Central Library</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    Sports News And Announcements
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Schemes</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Student Union</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="../Recogenition/recogenition.html"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    Student Grievance Portal
                  </span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1  mx-auto">
          <div className="row minusmrgin">
            <div className="col-md-12">
              <div className="boxed min700">
                <h1 className="text-primary-emphasis">
                  <div
                    className="container-fluid body-content content"
                    id="main-content"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      height="35px"
                    >
                      {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                      <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                    </svg>
                    Academic Calendar
                  </div>
                </h1>
                <hr />
                <form action="/n/academic-calendar" className="" method="get">
                  <table className="table table-warning">
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-group">
                            <label htmlFor="pageSize">Display Per Page</label>
                            <select
                              className="form-control"
                              id="pagesize"
                              name="pagesize"
                            >
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={15}>15</option>
                              <option selected="selected" value={20}>
                                20
                              </option>
                              <option value={25}>25</option>
                              <option value={30}>30</option>
                              <option value={35}>35</option>
                              <option value={40}>40</option>
                              <option value={45}>45</option>
                              <option value={50}>50</option>
                              <option value={55}>55</option>
                              <option value={60}>60</option>
                              <option value={65}>65</option>
                              <option value={70}>70</option>
                              <option value={75}>75</option>
                              <option value={80}>80</option>
                              <option value={85}>85</option>
                              <option value={90}>90</option>
                              <option value={95}>95</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="form-group">
                            <label htmlFor="name">Subject</label>
                            <input
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Subject"
                              type="text"
                              defaultValue=""
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group">
                            <label htmlFor="month">Month</label>
                            <select
                              className="form-control"
                              id="month"
                              name="month"
                            >
                              <option selected="selected" value={0}>
                                All
                              </option>
                              <option value={1}>January</option>
                              <option value={2}>February</option>
                              <option value={3}>March</option>
                              <option value={4}>April</option>
                              <option value={5}>May</option>
                              <option value={6}>June</option>
                              <option value={7}>July</option>
                              <option value={8}>August</option>
                              <option value={9}>September</option>
                              <option value={10}>October</option>
                              <option value={11}>November</option>
                              <option value={12}>December</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="form-group">
                            <label htmlFor="year">Year</label>
                            <select
                              className="form-control"
                              id="year"
                              name="year"
                            >
                              <option selected="selected" value={0}>
                                All
                              </option>
                              <option value={2019}>2019</option>
                              <option value={2020}>2020</option>
                              <option value={2021}>2021</option>
                              <option value={2022}>2022</option>
                              <option value={2023}>2023</option>
                              <option value={2024}>2024</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="form-group">
                            <label htmlFor="search"> </label>
                            <button
                              type="submit"
                              className="btn btn-success form-control"
                            >
                              Search!
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <table className="table table-hover table-striped table-bordered ">
                  <thead>
                    <tr className="table-warning">
                      <th>S No.</th>
                      <th>Subject</th>
                      <th>Notice Date</th>
                      <th>View/Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <a
                          href="/Uploads/academic calendar 2023-24_2023176134954.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2023-24</b>
                        </a>
                      </td>
                      <td>
                        <b>02-Jun-2023</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/academic calendar 2023-24_2023176134954.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalender2022-23_2023176134829.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2022-23</b>
                        </a>
                      </td>
                      <td>
                        <b>08-Jun-2022</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalender2022-23_2023176134829.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalendar2020-21_2023176134627.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2020-21</b>
                        </a>
                      </td>
                      <td>
                        <b>11-Nov-2020</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalendar2020-21_2023176134627.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalendar2019-20_compressed_2023176134542.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2019-20</b>
                        </a>
                      </td>
                      <td>
                        <b>25-May-2019</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalendar2019-20_compressed_2023176134542.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        <a
                          href="/Uploads/Academic-Calendar-2018-2019_compressed_2023176134354.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2018-19</b>
                        </a>
                      </td>
                      <td>
                        <b>13-Jun-2018</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/Academic-Calendar-2018-2019_compressed_2023176134354.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        <a
                          href="/Uploads/Academic-Calendar-2017-2018_2023176132856.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2017-18</b>
                        </a>
                      </td>
                      <td>
                        <b>11-May-2017</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/Academic-Calendar-2017-2018_2023176132856.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>
                        <a
                          href="/Uploads/AcadmicCalander16-17_2023176132800.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2016-17</b>
                        </a>
                      </td>
                      <td>
                        <b>07-Jul-2016</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcadmicCalander16-17_2023176132800.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>
                        <a
                          href="/Uploads/AcadmicCalander15-16_2023176132741.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2015-16</b>
                        </a>
                      </td>
                      <td>
                        <b>07-Jul-2015</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcadmicCalander15-16_2023176132741.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalendar2014_15_2023176132642.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2014-15</b>
                        </a>
                      </td>
                      <td>
                        <b>25-Jul-2014</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalendar2014_15_2023176132642.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalender_2013_14_2023176132601.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2013-14</b>
                        </a>
                      </td>
                      <td>
                        <b>25-Aug-2013</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalender_2013_14_2023176132601.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalender2012-13_2023176132535.pdf"
                          className=""
                          target="_blank"
                        >
                          <b>Academic Calendar 2012-13</b>
                        </a>
                      </td>
                      <td>
                        <b>25-Aug-2012</b>
                      </td>
                      <td>
                        <a
                          href="/Uploads/AcademicCalender2012-13_2023176132535.pdf"
                          className="btn btn-success"
                          target="_blank"
                        >
                          <i className="fa-solid fa-file-pdf" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div className="col-sm-10">
                  <div className="center">
                    <div className="pagination-container">
                      <ul className="pagination">
                        <li className="active">
                          <a>1</a>
                        </li>
                      </ul>
                    </div>
                    Page 1 of 1
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default AcademicCalender;