// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const DeanStudentWelfare= observer(() => {
  return (
    <div>
      <Header/>
        <>
  <div className="innerbanner" />
  <div className="col-md-12 row3 ">
    <div className=" boxed">
      <div className="top">
        <h3 className="text-primary-emphasis">
          Office of The Dean Students Welfare
        </h3>
        <div className="primarymenu">
          <nav
            className="navbar navbar-expand-lg"
            style={{ backgroundColor: "#1A2238" }}
          >
            <div className="container-sm">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbard"
                aria-controls="navbard"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbard">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-capitalize">
                  <li className="nav-item">
                    <a
                      href="https://abvv.ac.in/p/dsw-about"
                      className="nav-link nav-white"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://abvv.ac.in/p/dsw-message"
                      className="nav-link nav-white"
                    >
                      Message
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/cell/iqac" className="nav-link nav-white">
                      Students Welfare Schemes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://abvv.ac.in/p/dsw-activity"
                      className="nav-link nav-white"
                    >
                      Activities/Achievements
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/p/dsw-notification"
                      className="nav-link nav-white"
                    >
                      Notification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/cell/iqac" className="nav-link nav-white">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="row w-100">
        <div className="col-md-8 col-sm-8">
          <div className="swiper w-100">
            <div className="swiper-wrapper w-100">
              <div className="swiper-slide">
                <img className="h-50 w-100" src="" />
                <div className="img-caption" />
              </div>
            </div>
            <div className="swiper-pagination" />
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
          </div>
        </div>
        <div className="col-md-4 col-sm-4">
          <div className="panel panel-default boxed">
            <div className="panel-heading">
              <h3 className="text-primary-emphasis text-center">
                {" "}
                <i className="fa fa-newspaper-o" /> Notifications
              </h3>
            </div>
            <div className="panel-body p-3">
              <div className="row">
                <div className="col-xs-12">
                  <ul className="demo1 list-group">
                    <li className="news-item">
                      <span className="text-warning">
                        <b> 21-Jul-2023</b>{" "}
                      </span>
                      <a href="/n/Dean of Students' Welfare" className="">
                        <b>List of poor and needy students session 2023-24</b>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel-footer">
              <a href="/n/noticelist">View All Notices</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default DeanStudentWelfare;