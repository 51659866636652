// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const CentralLibrary= observer(() => {
  return (
    <div>
      <Header/>
        <>
  <section className="innerbanner" />
  <div className="container-fluid">
    <div className="row2">
      <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
        <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <h3 className="main-head">Central Library</h3>
          <ul
            className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
            id="menu"
          >
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Home</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">People</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="https://abvv-opac.kohacloud.in/"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">e-Library</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Facility</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Service</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Resource</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Achievement</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Download </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Notification</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Gallery</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Visitor view</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Research/Policies and Guidelines/policies_and _guidline.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Contact Us</span>
              </a>
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <div className="row1 mx-auto">
        <h3 className="main-head">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
          </svg>
          Central Library
        </h3>
        <div className="img-lib w-100">
          <img src="image/CentralLibraryM.jpg" className="w-100" alt="" />
        </div>
        <br />
        <h3 className="heading px-3">About Us</h3>
        <div className="about-us w-100 px-4">
          <br />
          <h5>About Department:</h5>
          <p>
            संक्षिप्त इतिहास - अटल बिहारी वाजपेयी विश्वविद्यालय में केन्द्रीय
            पुस्तकालय, विश्वविद्यालय के स्थापना काल से ही प्रारम्भ है। केन्द्रीय
            पुस्तकालय की सेवाओं का पूर्ण उपयोग करने विश्वविद्यालय के
            छात्र/छात्राओं, षिक्षकों, अधिकारीयों एवं कर्मचारीयों के आसान पहुच
            स्थान को ध्यान में रखकर इसे दिनांक 15 अगस्त 2019 को बिलासा सभागार
            परिसर के प्रथम तल में स्थानांतरित किया गया। जहाॅ केन्द्रीय ग्रंथालय
            अटल बिहारी वाजपेयी विश्वविद्यालय का उपयोग अधिकतम संतुष्टि हेतु आरम्भ
            हुआ।
          </p>
          <br />
          <h5>Objective (उद्देश्य):-:</h5>
          <br />
          <ul>
            <li>
              ग्रंथालय विज्ञान के पांच सूत्रों को आधार आदर्श मानकर, केन्द्रिय
              गं्रंथालय में संपुष्टि करना।
            </li>
            <li>
              उच्च कोटी की शोध एवं अनुसंधान में बेहतर संसाधन एवं स्त्रोत् उपलब्ध
              कराने के साथ छात्र-छात्राओं को अनुसंधान के लिए प्रेरित करना।
            </li>
            <li>
              ज्ञान एवं सूचना के अर्जन, व्यवस्थापन तथा प्रसारण को बढ़ावा देना।
            </li>
            <li>ग्रंथालयीन कर्मचारियों के तकनीकि दक्षता में वृद्धि करना।</li>
            <li>छात्र-छात्राओं क पठन-पाठन की मिमांसा को पूर्ण करना।</li>
          </ul>
          <br />
          <h5>Vision (मिशन):-</h5>
          <br />
          <ul>
            <li>
              पुस्तकालय एवं सूचना सेवा तथा प्रलेखन कार्य की गुणवक्ता में सुधार
              करना।
            </li>
            <li>
              विशिष्ट पुस्तकालय एवं सूचना केन्द्रों के मध्य सूचना एवं सेवा
              प्रसार के लिए परस्पर सहायोग में वृद्धि करना।
            </li>
            <li>
              मानवीय जिज्ञासा और उपयोगी अनुप्रयोग तथा ज्ञान के खुले को
              आदन-प्रदान को महत्व प्रदान करना।
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default CentralLibrary;