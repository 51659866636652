// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const VacanciesOfUniversity= observer(() => {
  return (
    <div>
      <Header/>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Recruitment</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/VacanciesOfUniversity"
                  className="nav-link align-middle px-0"
                  style={{ backgroundColor: "#03356E", pointerEvents: "none" }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    Vacancies In University
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/VacanciesOfColleges"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Vacancies in Colleges
                  </span>{" "}
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
            Vacancies In University
          </h3>
          <form action="/n/recognition">
            <table className="table table-warning">
              <tbody>
                <tr>
                  <td>
                    <div className="form-group">
                      <label htmlFor="pageSize">Display Per Page</label>
                      <select
                        className="form-control"
                        id="pagesize"
                        name="pagesize"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option selected="selected" value={20}>
                          20
                        </option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                        <option value={40}>40</option>
                        <option value={45}>45</option>
                        <option value={50}>50</option>
                        <option value={55}>55</option>
                        <option value={60}>60</option>
                        <option value={65}>65</option>
                        <option value={70}>70</option>
                        <option value={75}>75</option>
                        <option value={80}>80</option>
                        <option value={85}>85</option>
                        <option value={90}>90</option>
                        <option value={95}>95</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="name">Subject</label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Subject"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="month">Month</label>
                      <select className="form-control" id="month" name="month">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="year">Year</label>
                      <select className="form-control" id="year" name="year">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={2019}>2019</option>
                        <option value={2020}>2020</option>
                        <option value={2021}>2021</option>
                        <option value={2022}>2022</option>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="search"></label>
                      <button
                        type="submit"
                        className="btn btn-success form-control"
                      >
                        Search!
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <table className="table table2 table-hover table-striped table-bordered ">
            <thead>
              <tr className="table-warning">
                <th>S No.</th>
                <th>Subject</th>
                <th>Notice Date</th>
                <th>View/Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A5%8B%E0%A4%97%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%AE%E0%A4%B0,%20%E0%A4%95%E0%A5%81%E0%A4%B2%E0%A4%AA%E0%A4%A4%E0%A4%BF%20%E0%A4%95%E0%A5%87%20%E0%A4%A8%E0%A4%BF%E0%A4%9C%20%E0%A4%B8%E0%A4%B9%E0%A4%BE%E0%A4%AF%E0%A4%95,%20%E0%A4%B8%E0%A5%8D%E0%A4%9F%E0%A5%87%E0%A4%A8%E0%A5%8B%E0%A5%87%E0%A4%9F%E0%A4%BE%E0%A4%87%E0%A4%AA%E0%A4%BF%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%AA%E0%A4%A6%20%E0%A4%95%E0%A5%80%20%E0%A4%B2%E0%A4%BF%E0%A4%96%E0%A4%BF%E0%A4%A4%20%E0%A4%AA%E0%A4%B0%E0%A5%80%E0%A4%95%E0%A5%8D%E0%A4%B7%E0%A4%BE%20%E0%A4%B8%E0%A4%82%E0%A4%AC%E0%A4%82%E0%A4%A7%E0%A5%80%20%E0%A4%B8%E0%A5%82%E0%A4%9A%E0%A4%A8%E0%A4%BE%E0%A5%A4_20230610133924.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      प्रोग्रामर, कुलपति के निज सहायक, स्टेनोेटाइपिस्ट पद की
                      लिखित परीक्षा संबंधी सूचना।
                    </b>
                  </a>
                </td>
                <td>
                  <b>04-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="http://127.0.0.1:5500/Uploads/Non-Teaching%20Post_20230410150624.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <a
                    href="/Uploads/Non-Teaching Post_20230410150624.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      प्रोग्रामर, कुलपति के निज सहायक, स्टेनोेटाइपिस्ट पद की
                      लिखित परीक्षा संबंधी सूचना।
                    </b>
                  </a>
                </td>
                <td>
                  <b>04-Oct-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/Non-Teaching Post_20230410150624.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <a
                    href="/Uploads/सहायक ग्रंथपाल पद के लिए साक्षात्कार हेतु पात्र अभ्यर्थियों की सूची 21-09-2023_20230610131714.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      सहायक ग्रंथपाल पद के लिए साक्षात्कार हेतु पात्र
                      अभ्यर्थियों की सूची |
                    </b>
                  </a>
                </td>
                <td>
                  <b>21-Sep-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/सहायक ग्रंथपाल पद के लिए साक्षात्कार हेतु पात्र अभ्यर्थियों की सूची 21-09-2023_20230610131714.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <a
                    href="/Uploads/संचालक, शारीरिक शिक्षा पद के लिये शारीरिक दक्षता परीक्षण (Physical Fitness Test) हेतु पात्र अभ्यर्थियों की सूची 21-09-2023_20230610132917.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      संचालक, शारीरिक शिक्षा पद के लिये शारीरिक दक्षता परीक्षण
                      (Physical Fitness Test) हेतु पात्र अभ्यर्थियों की सूची |
                    </b>
                  </a>
                </td>
                <td>
                  <b>21-Sep-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/संचालक, शारीरिक शिक्षा पद के लिये शारीरिक दक्षता परीक्षण (Physical Fitness Test) हेतु पात्र अभ्यर्थियों की सूची 21-09-2023_20230610132917.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <a
                    href="/Uploads/संचालक, महाविद्यालयीन विकास परिषद् पद के लिए साक्षात्कार हेतु पात्र अभ्यर्थियों की सूची 21-09-2023_20230610133047.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      संचालक, महाविद्यालयीन विकास परिषद् पद के लिए साक्षात्कार
                      हेतु पात्र अभ्यर्थियों की सूची |{" "}
                    </b>
                  </a>
                </td>
                <td>
                  <b>21-Sep-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/संचालक, महाविद्यालयीन विकास परिषद् पद के लिए साक्षात्कार हेतु पात्र अभ्यर्थियों की सूची 21-09-2023_20230610133047.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <a
                    href="/Uploads/शैक्षणिक पदों पर भर्ती में दावा आपति के सम्बन्ध में । 22-06-2023_20230610133431.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      शैक्षणिक पदों पर भर्ती में दावा आपति के सम्बन्ध में ।{" "}
                    </b>
                  </a>
                </td>
                <td>
                  <b>22-Jun-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/शैक्षणिक पदों पर भर्ती में दावा आपति के सम्बन्ध में । 22-06-2023_20230610133431.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <a
                    href="/Uploads/नॉन टीचिंग भर्ती दावा आपति के सम्बन्ध में । 31-05-2023_20230610134120.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>नॉन टीचिंग भर्ती दावा आपति के सम्बन्ध में । </b>
                  </a>
                </td>
                <td>
                  <b>31-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/नॉन टीचिंग भर्ती दावा आपति के सम्बन्ध में । 31-05-2023_20230610134120.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <a
                    href="/Uploads/दीनदयाल उपाध्याय विश्वविद्यालय, गोरखपुर में कुलपति नियुक्ति संबंधी विज्ञापन । 31-05-2023_20230610135713.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      दीनदयाल उपाध्याय विश्वविद्यालय, गोरखपुर में कुलपति
                      नियुक्ति संबंधी विज्ञापन । 31-05-2023
                    </b>
                  </a>
                </td>
                <td>
                  <b>31-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/दीनदयाल उपाध्याय विश्वविद्यालय, गोरखपुर में कुलपति नियुक्ति संबंधी विज्ञापन । 31-05-2023_20230610135713.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  <a
                    href="/Uploads/gorkhpuruniversityvcvacancy_2023216132544.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      दीनदयाल उपाध्याय विश्वविद्यालय, गोरखपुर में कुलपति
                      नियुक्ति संबंधी विज्ञापन ।
                    </b>
                  </a>
                </td>
                <td>
                  <b>31-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/gorkhpuruniversityvcvacancy_2023216132544.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>
                  <a
                    href="/Uploads/DAVA AAPATTI Regarding Non Teaching Post 2023_2023216132620.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>नॉन टीचिंग भर्ती दावा आपति के सम्बन्ध में ।</b>
                  </a>
                </td>
                <td>
                  <b>31-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/DAVA AAPATTI Regarding Non Teaching Post 2023_2023216132620.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>
                  <a
                    href="/Uploads/2023-05-24 104552.282.ScanFile_2023216132720.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      किंग जार्ज मेडिकल विश्वविद्यालय, लखनऊ में कुलपति नियुक्ति
                      संबंधी विज्ञापन ।
                    </b>
                  </a>
                </td>
                <td>
                  <b>24-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/2023-05-24 104552.282.ScanFile_2023216132720.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>
                  <a
                    href="/Uploads/किंग जार्ज मेडिकल विश्वविद्यालय, लखनऊ में कुलपति नियुक्ति संबंधी विज्ञापन । 24-05-2023_20230610140008.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      किंग जार्ज मेडिकल विश्वविद्यालय, लखनऊ में कुलपति नियुक्ति
                      संबंधी विज्ञापन ।
                    </b>
                  </a>
                </td>
                <td>
                  <b>24-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/किंग जार्ज मेडिकल विश्वविद्यालय, लखनऊ में कुलपति नियुक्ति संबंधी विज्ञापन । 24-05-2023_20230610140008.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>13</td>
                <td>
                  <a
                    href="/Uploads/महात्मा ज्योतिबा फुले रूहेलखण्ड विश्वविद्यालय, बरेली (उ.प्र.) में कुलपति नियुक्ति संबंधी विज्ञापन  22-05-2023_20230610133653.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      महात्मा ज्योतिबा फुले रूहेलखण्ड विश्वविद्यालय, बरेली
                      (उ.प्र.) में कुलपति नियुक्ति संबंधी विज्ञापन |
                    </b>
                  </a>
                </td>
                <td>
                  <b>22-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/महात्मा ज्योतिबा फुले रूहेलखण्ड विश्वविद्यालय, बरेली (उ.प्र.) में कुलपति नियुक्ति संबंधी विज्ञापन  22-05-2023_20230610133653.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>14</td>
                <td>
                  <a
                    href="/Uploads/kulpati niyukti sambandhi_2023216132818.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      महात्मा ज्योतिबा फुले रूहेलखण्ड विश्वविद्यालय, बरेली
                      (उ.प्र.) में कुलपति नियुक्ति संबंधी विज्ञापन |
                    </b>
                  </a>
                </td>
                <td>
                  <b>22-May-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/kulpati niyukti sambandhi_2023216132818.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>
                  <a
                    href="/Uploads/administrative_2023216132940.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      Recruitment (Director - Physical Education, Director -
                      College Development Council , Assistant Librarian )
                    </b>
                  </a>
                </td>
                <td>
                  <b>25-Mar-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/administrative_2023216132940.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>16</td>
                <td>
                  <a
                    href="/Uploads/non-teaching_2023216133154.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Recruitment (Non Teaching Positions )</b>
                  </a>
                </td>
                <td>
                  <b>25-Mar-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/non-teaching_2023216133154.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>17</td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/public/home/contract/MQ=="
                    className=""
                    target="_blank"
                  >
                    <b>
                      Online Application Portal for Non Teaching Recruitment{" "}
                    </b>
                  </a>
                </td>
                <td>
                  <b>25-Mar-2023</b>
                </td>
                <td>
                  <a
                    href="https://exam.bucgexam.in/public/home/contract/MQ=="
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-link" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>18</td>
                <td>
                  <a
                    href="/Uploads/Recruitment (Non Teaching Positions )_20230610124458.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Recruitment (Non Teaching Positions )</b>
                  </a>
                </td>
                <td>
                  <b>25-Mar-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/Recruitment (Non Teaching Positions )_20230610124458.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>19</td>
                <td>
                  <a
                    href="/Uploads/Administrative Post_20230610125040.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      Recruitment (Director - Physical Education, Director -
                      College Development Council , Assistant Librarian )
                    </b>
                  </a>
                </td>
                <td>
                  <b>25-Mar-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/Administrative Post_20230610125040.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>20</td>
                <td>
                  <a
                    href="/Uploads/Notification Regarding Non Teachning Post_2023216134042.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Notification Regarding Non Teaching Post</b>
                  </a>
                </td>
                <td>
                  <b>21-Mar-2023</b>
                </td>
                <td>
                  <a
                    href="/Uploads/Notification Regarding Non Teachning Post_2023216134042.pdf"
                    className="btn btn-success"
                    target="_blank"
                  >
                    <i className="fa-solid fa-file-pdf" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
<Footer/>

    </div>
  );
}
);

export default VacanciesOfUniversity;