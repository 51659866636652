// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const AnnualReport= observer(() => {
  return (
    <div>
      <Header/>
      <>
  <section className="innerbanner" />
  <div className="row1">
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: "#1A2238" }}
    >
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="http://127.0.0.1:5500/department/departmenthome.html"
              >
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                People
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    faculty
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    staff
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="../../Department_cfs/Program/commerce_program.html"
              >
                program
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://127.0.0.1:5500/department/research.html"
              >
                research
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://127.0.0.1:5500/department/Activityall.html"
              >
                Activity
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://127.0.0.1:5500/department/facilities.html"
              >
                facility
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://127.0.0.1:5500/department/placement.html"
              >
                placement
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="http://127.0.0.1:5500/department/student.html"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                student
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    student related activity
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://127.0.0.1:5500/department/mentor.html"
                  >
                    mentor-mentee
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="">
                    Mooc_certificate
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Alumni
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    href="http://127.0.0.1:5500/department/aregister.html"
                  >
                    alumni Registration
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    our alumni
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                media
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    photo gallery
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    video gallery
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    news letter
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    publication
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Journal ans proceeding
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://127.0.0.1:5500/department/download.html"
              >
                download
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://127.0.0.1:5500/department/contact.html"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div className="container-fluid body-content content" id="main-content">
      <div className="row minusmrgin">
        <div className="col-md-12">
          <div className="boxed min700">
            <h3 className="text-primary-emphasis">
              <i className="fa-solid fa-calendar-check" /> Gallery List
            </h3>
            <form action="/home/eventlist" className="" method="get">
              <div className="container">
                <table className="table table-warning">
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-group">
                          <label htmlFor="pageSize">Display Per Page</label>
                          <input
                            data-val="true"
                            data-val-number="The field Int32 must be a number."
                            data-val-required="The Int32 field is required."
                            id="deptid"
                            name="deptid"
                            type="hidden"
                            defaultValue={0}
                          />
                          <select
                            className="form-control"
                            id="pagesize"
                            name="pagesize"
                          >
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option selected="selected" value={12}>
                              12
                            </option>
                            <option value={16}>16</option>
                            <option value={20}>20</option>
                            <option value={24}>24</option>
                            <option value={28}>28</option>
                            <option value={32}>32</option>
                            <option value={36}>36</option>
                            <option value={40}>40</option>
                            <option value={44}>44</option>
                            <option value={48}>48</option>
                            <option value={52}>52</option>
                            <option value={56}>56</option>
                            <option value={60}>60</option>
                            <option value={64}>64</option>
                            <option value={68}>68</option>
                            <option value={72}>72</option>
                            <option value={76}>76</option>
                            <option value={80}>80</option>
                            <option value={84}>84</option>
                            <option value={88}>88</option>
                            <option value={92}>92</option>
                            <option value={96}>96</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="name">Subject</label>
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Subject"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="month">Month</label>
                          <select
                            className="form-control"
                            id="month"
                            name="month"
                          >
                            <option selected="selected" value={0}>
                              All
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="year">Year</label>
                          <select
                            className="form-control"
                            id="year"
                            name="year"
                          >
                            <option selected="selected" value={0}>
                              All
                            </option>
                            <option value={2019}>2019</option>
                            <option value={2020}>2020</option>
                            <option value={2021}>2021</option>
                            <option value={2022}>2022</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="search"> </label>
                          <button
                            type="submit"
                            className="btn btn-success form-control"
                          >
                            Search!
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
            <div className="container">
              <div className="row">
                <div className="alert alert-success" role="alert">
                  <b>Sorry!</b>
                  Noting Found! We haven't found any data you requested.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

      <Footer/>
    </div>
  );
}
);

export default AnnualReport;