// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const UniversityInNews= observer(() => {
  return (
    <div>
      <Header/>
      <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Media</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/PhotoGallary"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Photo Gallery</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/VideoGallary"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Video Gallery</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/UniversityInNews"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    University in News
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/UniversityNewsLetter"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    University News Letter
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AnnualReport"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">Annual Report</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
            University News
          </h3>
          <div className="col-md-12">
            <div className="boxed minusmrgin">
              <div id="demo" className="carousel slide" data-bs-ride="carousel">
                {/* Indicators/dots */}
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to={0}
                    className="active"
                  />
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to={1}
                  />
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to={2}
                  />
                </div>
                {/* The slideshow/carousel */}
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="https://abvv.ac.in/uploads/Kulotsav121.JPG"
                      alt="Los Angeles"
                      className="mx-auto d-block"
                      style={{ width: "50%" }}
                    />
                  </div>{" "}
                  <br />
                  <div className="carousel-item">
                    <img
                      src="https://abvv.ac.in/uploads/BU_Newsletter_Vol_5.jpg"
                      alt="Chicago"
                      className="mx-auto d-block"
                      style={{ width: "50%" }}
                    />
                  </div>
                  <br />
                  <div className="carousel-item">
                    <img
                      src="https://abvv.ac.in/uploads/Kulotsav118.JPG"
                      alt="New York"
                      className="mx-auto d-block"
                      style={{ width: "50%" }}
                    />
                  </div>
                  <br />
                </div>
                {/* Left and right controls/icons */}
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" />
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" />
                </button>
              </div>
              <div className="container-fluid mt-3">
                <h3>University in News</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Commodi sit exercitationem molestiae..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

      <Footer/>
    </div>
  );
}
);

export default UniversityInNews;