// ReusableDataTable.js

import React from 'react';
import DataTable from 'react-data-table-component';

const CustomDataTable = ({ columns, data }) => {
  const customStyles = {
    header: {
      style: {
        minHeight: '30px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#0b559f',
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: '#0b559f',
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: '#0b559f',
        },
      },
    },
  };
  
  
  return (

    <DataTable
		title="Page List"
		columns={columns}
		data={data}
		customStyles={customStyles}
		pagination
		// selectableRows
		dense
	/>
  );
};

export default CustomDataTable;
