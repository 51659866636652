// src/pages/Home.js
import { observer } from 'mobx-react';
import React from 'react';

const Header= observer(() => {
  return (
    <div>
      <div className="w-100">
  <div className="top-nav external-url">
    <nav className="navbar navbar-expand-lg navbar-light bg-dark-subtle py-0">
      <div className="container-fluid">
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="navbar-collapse collapse" id="navbarText" style={{}}>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Quick Links
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <a className="nav-link" href="/n/tender">
                    Tender
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="nav-link" href="/VacanciesOfUniversity">
                    Recruitment
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="nav-link" href="/TimeTable">
                    Time Table
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="nav-link" href="/AllNotification">
                    All Notification
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="nav-link" href="/AffiliatedColleges">
                    Affiliated Colleges
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/DownloadNav">
                Download Forms
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://exam.bucgexam.in/"
                target="_blank"
              >
                Admission 2023-2024
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://abvvbilaspur.com/default.aspx"
                target="_blank"
              >
                Pre Ph.D. Online Form
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://abvv.ac.in/n/NIRF"
                target="_blank"
              >
                NIRF 2023
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://abvv.ac.in/n/Visitors%20View"
                target="_blank"
              >
                <b>Visitors View</b>
              </a>
            </li>
          </ul>
          <ul
            id="textresizer"
            className="top_menu nav nav-pills d-flex align-items-center pull-right"
          >
            <li>
              <a
                className="skiper local"
                title="Skip to Main Content"
                href="#main-content"
                tabIndex={1}
              >
                <i className="fa fa-arrow-down" />
              </a>
            </li>
            <li>
              <a
                title="Screen Reader Access"
                href="javascript:void();"
                className="external"
                tabIndex={2}
              >
                <i className="fa fa-volume-up" />
              </a>
            </li>
            <li>
              <a
                title="Sitemap"
                href="javascript:void();"
                className="external"
                tabIndex={3}
              >
                <i className="fa fa-sitemap" />
              </a>
            </li>
            <li className="textresizer">
              <a
                id="decreaseFont"
                className="decreaseFont"
                title="Smaller Font"
                href="javascript:void();"
                tabIndex={4}
              >
                A-
              </a>
            </li>
            <li className="textresizer">
              <a
                id="resetFont"
                className="resetFont"
                title="Medium Font"
                href="javascript:void();"
                tabIndex={5}
              >
                A
              </a>
            </li>
            <li className="textresizer">
              <a
                id="increaseFont"
                className="increaseFont"
                title="Larger Font"
                href="javascript:void();"
                tabIndex={6}
              >
                A+
              </a>
            </li>
            <li>
              <a
                className="theame defTheme default external"
                title="Default Theme"
                href="javascript:void();"
                tabIndex={7}
              >
                {" "}
                T{" "}
              </a>
            </li>
            <li>
              <a
                className="theame defTheme contrast external"
                title="Black Theme"
                href="javascript:void();"
                tabIndex={8}
              >
                {" "}
                T{" "}
              </a>
            </li>
            <li className="search">
              <span className="search-open">
                {" "}
                <i className="fa fa-search" />{" "}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <section className="logo-section">
    {/* <div class="sidebar">*/}
    <div className="col1">
      {/* <ul class="left-nav"> */}
      <div className="row11">
        <div className="logo-left">
          <a href="#">
            <img src="image/ABVV-NewLogo.png" alt="ABVV-NewLogo" />
          </a>
          <div className="heading mar">
            <h1 className="mar">अटल बिहारी वाजपेयी विश्‍वविद्यालय, बिलासपुर</h1>
            <span className="hindi-span mont">
              (छत्तीसगढ़ सरकार द्वारा अधिनियम 07/2012 द्वारा स्थापित पूर्ववर्ती
              बिलासपुर विश्वविद्यालय)
            </span>
            <h2 className="mont mar">
              Atal Bihari Vajpayee Vishwavidyalaya, Bilaspur
            </h2>
            <span className="hindi-span mont">
              (Formerly Bilaspur Vishwavidyalaya Established by Act No. 07/2012
              by Chhattisgarh Government)
            </span>
          </div>
        </div>
      </div>
      <div className="row22">
        <div className="logo-right">
          <a href="#">
            <img
              className="pm-usha"
              src="image/PM-UShA Logo-01.png"
              alt="PM-UShA"
            />
          </a>
          <div className="heading">
            <img src="image/logo-hi.png" alt="" />
          </div>
          <a href="#">
            <img src="image/AtalBihariVajpayee.png" alt="PM-UShA" />
          </a>
        </div>
      </div>
    </div>
  </section>
  <div className="main-nav w-100">
    <nav className="navbar navbar-expand-lg navbar-dark gradient-custom">
      <div className="container-fluid w-100">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link text-white" aria-current="page" href="/">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2 " href="/AboutAbvv">
                    About ABVV
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/AboutBilaspur">
                    About Bilaspur
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/ViceChancellorsMessage">
                    Vice Chancellor's Message
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/AboutKulgeet">
                    ABVV Kulgeet
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/Recognition">
                    Recognition
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Administration
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2 " href="/HonbleChancellor">
                    Hon'ble Chancellor
                  </a>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Vice-Chancellor
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/ViceChancellor">
                        Vice Chancellor
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/FormerViceChancellor">
                        Former Vice Chancellor
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/Registrar">
                    Registar
                  </a>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Executive Council
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/ECMember">
                        Members of the Executive Council
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/ECNotification">
                        Notification and Minutes of Meeting of the Executive
                        Council
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Academic Council
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/ACMember">
                        Members of the Academic Council
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/ACNotification">
                        Notification and Minutes of Meeting of the Academic
                        Council
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item py-2 " href="/DirectorPhysicalEducation">
                    Director, Physical Education
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2 " href="/ControllerOfExamination">
                    Controller of Examination
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2 " href="/FinanceOfficer">
                    Finance Offlicer
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2 " href="/Deputyregistrar">
                    Deputy Registrar
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2 " href="/AssistantRegistrar">
                    Assistant Registrar
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2 " href="/DeanOfStudentsWelfare">
                    Dean of Student's Welfare
                  </a>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Finance Committee
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/FCMember">
                        Members of the Finance Committee
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/FCNotification">
                        Notification and Minutes of Meeting of the Finance
                        Committee
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Deans of Schools
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/DOSMember">
                        Members of the Deans of Schools
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/DOSNotification ">
                        Notification and Minutes of Meeting of the Deans of
                        Schools
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Board of Studies
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/BOSMember ">
                        Members of the Board of Studies
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/BOSNotification">
                        Notification and Minutes of Meeting of the Board of
                        Studies
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Different Cells
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/DCTrainingcell">
                        Training and Placement Cell
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Academics
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2 " href="/PraveshMargdarshika">
                    Pravesh Margdarshika
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/AffiliatedColleges">
                    Affiliated Colleges
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/CollegeWithSubject">
                    Colleges with Subjects
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/Seniority">
                    Seniority List
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/RPIUniversity">
                    Research Projects in University
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="RFIUniversity">
                    Research Facilities in University
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/ResearchFellowship">
                    Research Fellowship
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/StartupAtal">
                    Startup Atal University
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/MoUs">
                    MoUs
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/Soudhpeeth">
                    Shodhpeeth
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/Awards">
                    Awards and Honours
                  </a>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ordinance/Regulation/Guidelines
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="/Ordinance">
                        Ordinance
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/Regulation">
                        Regulation
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item py-2" href="/Guidelines">
                        Guidelines
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Amenities
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Central Library
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Health Centre
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    GYM
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Sports
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Placement Cell
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Auditorium
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Kilkari
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Cafeteria
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Meeting Hall &amp; Conference Hall
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    N-Care
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Guest House
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Language Lab
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/Department">
                Department
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Student's Corner
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2" href="/DeanStudentWelfare">
                    Dean Student's Welfare
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/AcademicCalender">
                    Academic Calendar
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/Course">
                    Courses
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Admission Notification
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Latest Notification
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    College Notification
                  </a>""
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    National service Scheme
                  </a>
                </li>
                <li className="nav-item dropend">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    UTD National Service Scheme
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item py-2 " href="#">
                        UTD NSS Notification/Newsletter/Report
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Download
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/CentralLibrary">
                    Central Library
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Sports News and Announcements
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Schemes
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Student Union
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="#">
                    Student Grievance Portal
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Media
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2 " href="/PhotoGallary">
                    Photo Gallary
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/VideoGallary">
                    Video Gallary
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/UniversityInNews">
                    University in News
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/UniversityNewsLetter">
                    University Newsletter
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/AnnualReport">
                    Annual Report
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/CommitteeCell">
                Committees/Cells
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/Iqac">
                IQAC
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Recruiment
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item py-2 " href="/VacanciesOfUniversity">
                    Vacancies in University
                  </a>
                </li>
                <li>
                  <a className="dropdown-item py-2" href="/VacanciesOfColleges">
                    Vacancies in Colleges
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/OnlineServices">
                Online Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/ContactUs">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>

    </div>
  );
}
);

export default Header;