// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const Recognition= observer(() => {
  return (
    <div>
        <Header/>
      
  <section className="innerbanner" />
  <div style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-start  px-3 pt-2 text-white">
            <h3 className="main-head">Recogenition</h3>
            <ul
              className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/AboutAbvv"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About ABVV</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutBilaspur"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Bilaspur</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ViceChancellorsMessage"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline gd">
                    Vice Chancellor's Message
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AboutKulgeet"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1  d-sm-inline gd">About Kulgeet</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Recognition"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline gd"
                    style={{ color: "#fff" }}
                  >
                    Recogenition
                  </span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
            Recognition
          </h3>
          <form action="/n/recognition">
            <table className="table table-warning">
              <tbody>
                <tr>
                  <td>
                    <div className="form-group">
                      <label htmlFor="pageSize">Display Per Page</label>
                      <select
                        className="form-control"
                        id="pagesize"
                        name="pagesize"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option selected="selected" value={20}>
                          20
                        </option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                        <option value={40}>40</option>
                        <option value={45}>45</option>
                        <option value={50}>50</option>
                        <option value={55}>55</option>
                        <option value={60}>60</option>
                        <option value={65}>65</option>
                        <option value={70}>70</option>
                        <option value={75}>75</option>
                        <option value={80}>80</option>
                        <option value={85}>85</option>
                        <option value={90}>90</option>
                        <option value={95}>95</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="name">Subject</label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Subject"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="month">Month</label>
                      <select className="form-control" id="month" name="month">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="year">Year</label>
                      <select className="form-control" id="year" name="year">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={2019}>2019</option>
                        <option value={2020}>2020</option>
                        <option value={2021}>2021</option>
                        <option value={2022}>2022</option>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="search"> </label>
                      <button
                        type="submit"
                        className="btn btn-success form-control"
                      >
                        Search!
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <table className="table table-hover table-striped table2">
            <thead>
              <tr className="table-warning">
                <th>S No.</th>
                <th>Subject</th>
                <th>Notice Date</th>
                <th>View/Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/12b-by-UGC_2023235095015.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Approval of 12B Status by UGC.</b>
                  </a>
                </td>
                <td>
                  <b> 15-Sep-2021 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/12b-by-UGC_2023235095015.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/SCAN_20190227_042235965_2023245073521.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>UGC Letter Regarding University Name Change.</b>
                  </a>
                </td>
                <td>
                  <b> 19-Feb-2019 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/SCAN_20190227_042235965_2023245073521.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/Rajpatra-Atal-Bihari-University-Bilaspur_2023235093714.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      Gazette Notification (Atal Bihari Vajpayee Vishwavidyalaya
                      )
                    </b>
                  </a>
                </td>
                <td>
                  <b> 12-Sep-2018 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/Rajpatra-Atal-Bihari-University-Bilaspur_2023235093714.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/TheBarCouncilrecognition_2023235093100.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Recognized by The Bar Council of India</b>
                  </a>
                </td>
                <td>
                  <b> 28-Sep-2017 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/TheBarCouncilrecognition_2023235093100.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/AIU_MemberFile_2023235093858.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Member of Association of Indian University (AIU).</b>
                  </a>
                </td>
                <td>
                  <b> 23-Oct-2012 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/AIU_MemberFile_2023235093858.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/UGC-Recognition_2023225095640.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>
                      Recognized by University Grant Commission (UGC), New
                      Delhi.
                    </b>
                  </a>
                </td>
                <td>
                  <b> 18-Sep-2012 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/UGC-Recognition_2023225095640.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/CG-BU-Rajpatra_2023235093526.pdf"
                    className=""
                    target="_blank"
                  >
                    <b>Gazette Notification (Bilaspur Vishwavidyalaya)</b>
                  </a>
                </td>
                <td>
                  <b> 03-Feb-2012 </b>
                </td>
                <td>
                  <a
                    href="https://abvv.ac.in/Uploads/CG-BU-Rajpatra_2023235093526.pdf"
                    className=""
                    target="_blank"
                  >
                    <img src="image/pdf.png" width="50px" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <Footer/>


    </div>
  );
}
);

export default Recognition;