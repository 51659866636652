// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const DOSNotification= observer(() => {
  return (
    <div>
      <Header/>
        <>
  <section className="innerbanner" />
  <div className="width100">
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Administration</h3>
            <ul
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/HonbleChancellor"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Hon'ble Chancellor
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu1"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-speedometer2" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Vice Chancellor
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu1"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/ViceChancellor"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Vice Chancellor
                      </span>{" "}
                      {" "}
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/FormerViceChancellor"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Former Vice Chancellor
                      </span>{" "}
                      {" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="/Registrar"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">Registrar</span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu2"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle "
                >
                  <i className="fs-4 bi-bootstrap" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Executive Council
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu2"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/ECMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Member Of The Executive Council
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/ECNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Executive
                        Council
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu8"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Academic Council
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu8"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/ACMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Member Of The Academc Council
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/ACNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Academic
                        Council
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="/DirectorPhysicalEducation"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Director,Physical Education
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/ControllerOfExamination"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Controller Of Examination
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/FinanceOfficer"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Finance Officer
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/Deputyregistrar"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Deputy Registrar
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AssistantRegistrar"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Assistant Registrar
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/DeanOfStudentsWelfare"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-table" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Dean Of Students' Welfare
                  </span>{" "}
                </a>
              </li>
              <li className="w-100">
                <a
                  href="#submenu3"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Finance Committee
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu3"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/FCMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Member Of The Finance Committee
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/FCNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Academic
                        Council
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu5"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Dean Of Schools
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu5"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/DOSMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Member Of The Dean Of Schools
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/DOSNotification"
                      className="nav-link px-0"
                      style={{
                        borderLeft: "4px solid #03356E",
                        backgroundColor: "#03356E",
                        pointerEvents: "none"
                      }}
                    >
                      {" "}
                      <span
                        className="d-none d-sm-inline asd"
                        style={{ color: "#fff" }}
                      >
                        Notification And Minutes Of Meeting Of The Dean Of
                        Schools
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu6"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Board Of Studies
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu6"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/BOSMember"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Member Of The Board Of Studies
                      </span>{" "}
                      
                    </a>
                  </li>
                  <li className="w-100">
                    <a
                      href="/BOSNotification"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Notification And Minutes Of Meeting Of The Board Of
                        Studies
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
              <li className="w-100">
                <a
                  href="#submenu7"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle"
                >
                  <i className="fs-4 bi-grid" />{" "}
                  <span className="ms-1 d-none d-sm-inline asd">
                    Different Cells
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <svg
                    style={{ width: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </a>
                <ul
                  className="collapse nav flex-column ms-1"
                  id="submenu7"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <a
                      href="/DCTrainingcell"
                      className="nav-link px-0"
                    >
                      {" "}
                      <span className="d-none d-sm-inline ">
                        Training And Placement Cell
                      </span>{" "}
                      
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1">
          <h3 className="main-head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#052c65"
            >
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
            Notification and Minutes of Meeting of the Deans of Schools
          </h3>
          <form>
            <div className="form">
              <div>
                <label htmlFor="inlineFormInput">Display Per Page</label>
                <select className="form-control">
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option selected="selected" value={20}>
                    20
                  </option>
                  <option value={25}>25</option>
                  <option value={30}>30</option>
                  <option value={35}>35</option>
                  <option value={40}>40</option>
                  <option value={45}>45</option>
                  <option value={50}>50</option>
                  <option value={55}>55</option>
                  <option value={60}>60</option>
                  <option value={65}>65</option>
                  <option value={70}>70</option>
                  <option value={75}>75</option>
                  <option value={80}>80</option>
                  <option value={85}>85</option>
                  <option value={90}>90</option>
                  <option value={95}>95</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div>
                <label htmlFor="inlineFormInput">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Subject"
                />
              </div>
              <div>
                <label htmlFor="inlineFormInput">Month</label>
                <select className="form-control">
                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </select>
              </div>
              <div>
                <label htmlFor="inlineFormInput">Year</label>
                <select className="form-control">
                  <option value={2019}>2019</option>
                  <option value={2020}>2020</option>
                  <option value={2021}>2021</option>
                  <option value={2022}>2022</option>
                  <option value={2023}>2023</option>
                  <option value={2024}>2024</option>
                </select>
              </div>
              <div>
                <button type="submit" className="btn">
                  Search!
                </button>
              </div>
            </div>
          </form>
          <div className="alert-success" role="alert">
            <b>Sorry!</b>
            Noting Found! The Query returned 0 records.
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default DOSNotification;