// src/pages/Home.js
import { observer } from 'mobx-react';
import React from 'react';

const DownloadNav= observer(() => {
  return (
    <div>
      <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid w-100">
      <div className="row2">
        <div className="col-auto col-md-11 col-xl-11 px-sm-12  bg-light mx-auto">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Download</h3>
            <form action="/home/eventlist" className="" method="get">
              <div className="container">
                <table className="table table-warning">
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-group">
                          <label htmlFor="pageSize">Display Per Page</label>
                          <input
                            data-val="true"
                            data-val-number="The field Int32 must be a number."
                            data-val-required="The Int32 field is required."
                            id="deptid"
                            name="deptid"
                            type="hidden"
                            defaultValue={0}
                          />
                          <select
                            className="form-control"
                            id="pagesize"
                            name="pagesize"
                          >
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option selected="selected" value={12}>
                              12
                            </option>
                            <option value={16}>16</option>
                            <option value={20}>20</option>
                            <option value={24}>24</option>
                            <option value={28}>28</option>
                            <option value={32}>32</option>
                            <option value={36}>36</option>
                            <option value={40}>40</option>
                            <option value={44}>44</option>
                            <option value={48}>48</option>
                            <option value={52}>52</option>
                            <option value={56}>56</option>
                            <option value={60}>60</option>
                            <option value={64}>64</option>
                            <option value={68}>68</option>
                            <option value={72}>72</option>
                            <option value={76}>76</option>
                            <option value={80}>80</option>
                            <option value={84}>84</option>
                            <option value={88}>88</option>
                            <option value={92}>92</option>
                            <option value={96}>96</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="name">Subject</label>
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Subject"
                            type="text"
                            defaultValue=""
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="month">Month</label>
                          <select
                            className="form-control"
                            id="month"
                            name="month"
                          >
                            <option selected="selected" value={0}>
                              All
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="year">Year</label>
                          <select
                            className="form-control"
                            id="year"
                            name="year"
                          >
                            <option selected="selected" value={0}>
                              All
                            </option>
                            <option value={2019}>2019</option>
                            <option value={2020}>2020</option>
                            <option value={2021}>2021</option>
                            <option value={2022}>2022</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <label htmlFor="search"></label>
                          <button
                            type="submit"
                            className="btn btn-success form-control"
                          >
                            Search!
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>{" "}
            <div className="container">
              <div className="row">
                <div className="alert alert-success" role="alert">
                  <b>Sorry!</b>
                  Noting Found! We haven't found any data you requested.
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div> 
      </div>
    </div>
  </div>
</> 

    </div>
  );
}
);

export default DownloadNav;