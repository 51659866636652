/* eslint-disable */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import BaseRouter from "./routes";
import { observer } from "mobx-react";

const App = observer(() => {
  // Initialize React Ga with your tracking ID


  return (
    <React.Fragment>
      <Router>
        <BaseRouter />
      </Router>
    </React.Fragment>
  );
});
export default App;