// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const Department = observer(() => {
    return (
        <div>
            <Header />
            
                <section className="innerbanner" />
                <div className="row0">
                    <div className="row111 w-auto">
                        <h3 className="main-head">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                            </svg>
                        </h3>
                        <div className="content">
                            <h1>Department</h1>
                            <div className="comm">
                                <div className="row row-cols-1 row-cols-md-3 g-5">
                                    <div className="col">
                                        <div className="card card1">
                                            <img
                                                src="image/CC&PC.jpeg"
                                                className="card-img-top"
                                                alt="Department"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Department of Computer Science &amp; Application
                                                </h5>
                                                <button type="button" className="btn btn-success btn-sm">
                                                    <a href="../../career_counceling.html">read more</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card1">
                                            <img
                                                src="image/Students GRC.jpeg"
                                                className="card-img-top"
                                                alt="Department"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Department of Commerce &amp; Financial Studies
                                                </h5>
                                                <button type="button" className="btn btn-success btn-sm">
                                                    <a href="../../Student_grievance.html">read more</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card1">
                                            <img
                                                src="image/Academic Activity cell.jpeg"
                                                className="card-img-top"
                                                alt="Department"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Department of Food Processing &amp; Technology
                                                </h5>
                                                <button type="button" className="btn btn-success btn-sm">
                                                    <a href="../../academic_activity.html">read more</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card1">
                                            <img
                                                src="image/Research Project Pic.jpeg"
                                                className="card-img-top"
                                                alt="Department"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Department of Hotel Management &amp; Hospitality
                                                </h5>
                                                <button type="button" className="btn btn-success btn-sm">
                                                    <a href="../../Reaserch_pramotion.html">read more</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card1">
                                            <img
                                                src="image/Conference and Seminar.jpeg"
                                                className="card-img-top"
                                                alt="Department"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Department of Microbiology &amp; Bio-informatics
                                                </h5>
                                                <button type="button" className="btn btn-success btn-sm">
                                                    <a href="../../Conference and Seminar.html">read more</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card1">
                                            <img
                                                src="image/Equal Opportunity cell.jpeg"
                                                className="card-img-top"
                                                alt="Department"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">Department of Yoga Science</h5>
                                                <button type="button" className="btn btn-success btn-sm">
                                                    <a href="">read more</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <Footer />
            </div>
            );
}
);

            export default Department;