// src/components/Dashboard.js
import React from 'react';
import SimpleChart from './SimpleChart';


const Dashboard = () => {
  return (
    <div className="container mt-4">
      <h1>Admin Dashboard</h1>
      <div className="row">
        <div className="col-md-6">
        <SimpleChart />
        </div>
        <div className="col-md-6">
        <SimpleChart />
        </div>
      </div>
    </div>
  );
}
;

export default Dashboard;
