// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const Course= observer(() => {
  return (
    <div>
        <Header/>
<>
  <section className="innerbanner" />
  <div className="container-fluid">
    <div className="row2">
      <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
        <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <h3 className="main-head">Students Corner</h3>
          <ul
            className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
            id="menu"
          >
            <li className="w-100">
              <a
                href="/DeanStudentWelfare"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  Dean Student's Welfare
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/AcademicCalender"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">Academic Calender</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Course"
                className="nav-link align-middle px-0"
                style={{
                  borderLeft: "4px solid #03356E",
                  backgroundColor: "#03356E",
                  pointerEvents: "none"
                }}
              >
                <i className="fs-4 bi-house" />{" "}
                <span
                  className="ms-1  d-sm-inline asd"
                  style={{ color: "#fff" }}
                >
                  Course
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Admission_notification/Admission_notifaction.html"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  Admission Notification
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../latest_notification/ALL_notification.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  Latest Notification
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  College Notification
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  National Service Scheme
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="#submenu2"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle "
              >
                <i className="fs-4 bi-bootstrap" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  UTD National Service Scheme
                </span>
                &nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu2"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a href="" className="nav-link px-0">
                    {" "}
                    <span className=" d-sm-inline asd">
                      UTD NSS Notification/NewsLetter/Report
                    </span>{" "}
                    1
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">Download</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/CentralLibrary"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">Central Library</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  Sports News And Announcements
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">Schemes</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">Student Union</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="../Recogenition/recogenition.html"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline asd">
                  Student Grievance Portal
                </span>
              </a>
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <div className="row1 mx-auto">
        <div className="row minusmrgin">
          <div className="col-md-12">
            <div className="boxed minusmrgin">
              <h3 className="text-primary-emphasis">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  height="35px"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                </svg>{" "}
                Course{" "}
              </h3>
              <table className="table table-bordered border-primary">
                <tbody>
                  <tr>
                    <td>Course Code</td>
                    <td>Name of Course</td>
                    <td>Type of Course</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>B.A. PART-I (ONE) (REGULAR)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>B.A. PART-II (TWO)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>B.A. PART-III (THREE)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>B. SC. PART-II (TWO)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>B. SC. PART-III (THREE)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>B.COM PART-I (ONE) (10+2+3) </td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>B.COM PART-II (TWO) (10+2+3) </td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>B.COM PART-III (THREE) (10+2+3) </td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>B.Sc. HOME SCIENCE PART-I</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>B.Sc. HOME SCIENCE PART-II</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>B.Sc. HOME SCIENCE PART-III</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>B.B.A. PART-I (ONE)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>B.B.A. PART-II (TWO)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>B.B.A. PART-III (THREE)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>B.C.A. PART-II (TWO)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>B.C.A. PART-III (THREE)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>49</td>
                    <td>B. SC. PART-I (MATHS GROUP)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>B. SC. PART-I (BIO-GROUP)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>59</td>
                    <td>M.Sc. (Final) Geology</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>61</td>
                    <td>M.Sc. (Final) Microbiology</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>63</td>
                    <td>M.Sc. (Final) Computer Science </td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>69</td>
                    <td>MASTER OF SOCIAL WORK (FINAL)</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>70</td>
                    <td>P.G. DIPLOMA IN BUSINESS MANAGEMENT</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>71</td>
                    <td>P.G. DIPLOMA IN BANKING</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>72</td>
                    <td>P.G. DIPLOMA IN COMPUTER APPLICATIONS</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>75</td>
                    <td>BACHELOR OF PHYSICAL EDUCATION (FIRST YEAR)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>76</td>
                    <td>BACHELOR OF PHYSICAL EDUCATION (SECOND YEAR)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>78</td>
                    <td>B.LIB (I YEAR DEGREE COURSE)</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>86</td>
                    <td>LL.M. (I SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>87</td>
                    <td>LL.M. (II SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>88</td>
                    <td>LL.M. (III SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>89</td>
                    <td>LL.M. (IV SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>91</td>
                    <td>LL.B. PART ONE (I SEM.) (OLD COURSE)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>95</td>
                    <td>LL.B. PART THREE (I SEM.) (OLD COURSE)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>100</td>
                    <td>DIPLOMA IN COMPUTER APPLICATION</td>
                    <td>DIPLOMA</td>
                  </tr>
                  <tr>
                    <td>101</td>
                    <td>B.A. L.L.B. (I SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>102</td>
                    <td>B.A. L.L.B. (II SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>103</td>
                    <td>B.A. L.L.B. (III SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>104</td>
                    <td>B.A. L.L.B. (IV SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>105</td>
                    <td>B.A. L.L.B. (V SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>106</td>
                    <td>B.A. L.L.B. (VI SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>107</td>
                    <td>B.A. L.L.B. (VII SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>108</td>
                    <td>B.A. L.L.B. (VIII SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>109</td>
                    <td>B.A. L.L.B. (IX SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>110</td>
                    <td>B.A. L.L.B. (X SEM.)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>111</td>
                    <td>B.COM. LL.B. FIRST SEMESTER (OLD COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>113</td>
                    <td>B.COM. LL.B. THIRD SEMESTER (OLD COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>131</td>
                    <td>B.COM I SEM. (UNDER 5YRS INT. COURSE) (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>132</td>
                    <td>B.COM II SEM. (UNDER 5YRS INT. COURSE)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>133</td>
                    <td>B.COM III SEM. (UNDER 5YRS INT. COURSE) (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>134</td>
                    <td>B.COM IV SEM. (UNDER 5YRS INT. COURSE)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>135</td>
                    <td>B.COM V SEM. (UNDER 5YRS INT. COURSE) (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>136</td>
                    <td>B.COM VI SEM. (UNDER 5YRS INT. COURSE)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>137</td>
                    <td>B.COM VII SEM. (UNDER 5YRS INT. COURSE) (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>138</td>
                    <td>B.COM VIII SEM. (UNDER 5YRS INT. COURSE)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>139</td>
                    <td>B.COM IX SEM. (UNDER 5YRS INT. COURSE) (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>140</td>
                    <td>B.COM X SEM. (UNDER 5YRS INT. COURSE)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>141</td>
                    <td>
                      B.SC. I SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                      (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>142</td>
                    <td>
                      B.SC. II SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>143</td>
                    <td>
                      B.SC. III SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                      (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>144</td>
                    <td>
                      B.SC. IV SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>145</td>
                    <td>
                      B.SC. V SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                      (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>146</td>
                    <td>
                      B.SC. VI SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>147</td>
                    <td>
                      M.SC. I SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                      (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>148</td>
                    <td>
                      M.SC. II SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE){" "}
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>149</td>
                    <td>
                      M.SC. III SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                      (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>150</td>
                    <td>
                      M.SC. IV SEM. COMPUTER SCIENCE (UNDER 5YRS INT. COURSE)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>151</td>
                    <td>
                      BACHELOR IN HOTEL MGMT.&amp; HOSPITALITY I SEM.(UNDER 3YR
                      DEGREE COURSE) (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>152</td>
                    <td>
                      BACHELOR. IN HOTEL MGMT.&amp; HOSP. II SEM.(UNDER 3YR
                      D.C.)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>153</td>
                    <td>
                      BACHELOR IN HOTEL MGMT.&amp; HOSPITALITY III SEM.(UNDER
                      3YR DEGREE COURSE) (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>154</td>
                    <td>
                      BACHELOR IN HOTEL MGMT.&amp; HOSP. IV SEM.(UNDER 3YR D.C.)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>155</td>
                    <td>
                      BACHELOR IN HOTEL MGMT.&amp; HOSPITALITY V SEM.(UNDER 3YR
                      DEGREE COURSE) (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>156</td>
                    <td>
                      BACHELOR IN HOTEL MGMT.&amp; HOSP. VI SEM.(UNDER 3YR D.C.)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>161</td>
                    <td>
                      M.SC. MICROBIOLOGY &amp; BIOINFORMATICS I SEM (UNDER 2YR
                      POST GRADUATE COURSE) (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>162</td>
                    <td>
                      M.SC. MICROBIOLOGY &amp; BIOINF. II SEM (UNDER 2YR P.G.
                      C.)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>163</td>
                    <td>
                      M.SC. MICROBIOLOGY &amp; BIOINFORMATICS III SEM (UNDER 2YR
                      POST GRADUATE COURSE) (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>164</td>
                    <td>
                      M.SC. MICROBIOLOGY &amp; BIOINF. IV SEM (UNDER 2YR P.G.
                      C.)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>166</td>
                    <td>
                      M.SC. MICROBIOLOGY &amp; BIOINF. VI SEM (UNDER 2YR P.G.C.)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>171</td>
                    <td>
                      B.SC. I SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS INT.
                      COURSE) (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>172</td>
                    <td>
                      B.SC. II SEM. FOOD PROC. &amp; TECH. (UNDER 5YRS INT.
                      COURSE)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>173</td>
                    <td>
                      B.SC. III SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS
                      INT. COURSE) (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>174</td>
                    <td>
                      B.SC. IV SEM. FOOD PROC. &amp; TECH. (UNDER 5YRS INT.
                      COURSE)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>175</td>
                    <td>
                      B.SC. V SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS INT.
                      COURSE) (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>176</td>
                    <td>
                      B.SC. VI SEM. FOOD PROC. &amp; TECH. (UNDER 5YRS INT.
                      COURSE)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>177</td>
                    <td>
                      M.SC. I SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS INT.
                      COURSE) (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>178</td>
                    <td>
                      M.SC. II SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS INT.
                      COURSE)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>179</td>
                    <td>
                      M.SC. III SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS
                      INT. COURSE) (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>180</td>
                    <td>
                      M.SC. IV SEM. FOOD PROCESSING &amp; TECH. (UNDER 5YRS INT.
                      COURSE)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>202</td>
                    <td>
                      Post Graduate Certificate in Cyber Security and Cyber Law{" "}
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>204</td>
                    <td>CERTIFICATE COURSE IN GOODS AND SERVICES TAX (GST)</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>209</td>
                    <td>CERTIFICATE COURSE IN MUSHROOM TECHNOLOGY</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>210</td>
                    <td>CERTIFICATE COURSE IN COMMUNICATION SKILLS</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>211</td>
                    <td>
                      CERTIFICATE COURSE IN ENTREPRENEURIAL SKILL DEVELOPMENT
                    </td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>212</td>
                    <td>CERTIFICATE COURSE IN FOOD PRODUCTION</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>213</td>
                    <td>CERTIFICATE COURSE IN HOUSE KEEPING</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>214</td>
                    <td>CERTIFICATE COURSE IN BIOINFORMATICS</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>215</td>
                    <td>PG CERTIFICATE IN ICT TOOLS FOR TEACHING-LEARNING</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>216</td>
                    <td>PG CERTIFICATE IN PYTHON PROGRAMMING</td>
                    <td>UTD-CER</td>
                  </tr>
                  <tr>
                    <td>301</td>
                    <td>P.G.DIPLOMA IN YOGA SCIENCE I SEM.</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>302</td>
                    <td>P.G.DIPLOMA IN YOGA SCIENCE II SEM.</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>303</td>
                    <td>P.G.DIPLOMA IN C.B. I SEM</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>304</td>
                    <td>P.G.DIPLOMA IN C.B. II SEM</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>402</td>
                    <td>M.A. ENGLISH (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>403</td>
                    <td>M.A. ENGLISH (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>404</td>
                    <td>M.A. ENGLISH (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>412</td>
                    <td>M.A. HINDI (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>413</td>
                    <td>M.A. HINDI (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>414</td>
                    <td>M.A. HINDI (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>422</td>
                    <td>M.A. SANSKRIT (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>423</td>
                    <td>M.A. SANSKRIT (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>424</td>
                    <td>M.A. SANSKRIT (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>432</td>
                    <td>M.A. HISTORY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>433</td>
                    <td>M.A. HISTORY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>434</td>
                    <td>M.A. HISTORY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>442</td>
                    <td>M.A. POLITICAL SCIENCE (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>443</td>
                    <td>M.A. POLITICAL SCIENCE (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>444</td>
                    <td>M.A. POLITICAL SCIENCE (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>452</td>
                    <td>M.A. SOCIOLOGY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>453</td>
                    <td>M.A. SOCIOLOGY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>454</td>
                    <td>M.A. SOCIOLOGY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>461</td>
                    <td>M.A. ECONOMICS (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>462</td>
                    <td>M.A. ECONOMICS (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>463</td>
                    <td>M.A. ECONOMICS (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>464</td>
                    <td>M.A. ECONOMICS (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>471</td>
                    <td>M.A. GEOGRAPHY (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>472</td>
                    <td>M.A. GEOGRAPHY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>473</td>
                    <td>M.A. GEOGRAPHY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>474</td>
                    <td>M.A. GEOGRAPHY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>481</td>
                    <td>M.A. PSYCHOLOGY (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>482</td>
                    <td>M.A. PSYCHOLOGY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>483</td>
                    <td>M.A. PSYCHOLOGY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>484</td>
                    <td>M.A. PSYCHOLOGY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>491</td>
                    <td>M.A. PHILOSOPHY (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>492</td>
                    <td>M.A. PHILOSOPHY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>493</td>
                    <td>M.A. PHILOSOPHY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>494</td>
                    <td>M.A. PHILOSOPHY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>502</td>
                    <td>M.A. PUBLIC ADMINISTRATION (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>503</td>
                    <td>M.A. PUBLIC ADMINISTRATION (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>504</td>
                    <td>M.A. PUBLIC ADMINISTRATION (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>511</td>
                    <td>M.A./M.Sc. MATHEMATICS (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>512</td>
                    <td>M.A./M.Sc. MATHEMATICS (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>513</td>
                    <td>M.A./M.Sc. MATHEMATICS (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>514</td>
                    <td>M.A./M.Sc. MATHEMATICS (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>522</td>
                    <td>M.Sc. PHYSICS (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>523</td>
                    <td>M.Sc. PHYSICS (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>524</td>
                    <td>M.Sc. PHYSICS (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>532</td>
                    <td>M.Sc. CHEMISTRY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>533</td>
                    <td>M.Sc. CHEMISTRY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>534</td>
                    <td>M.Sc. CHEMISTRY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>542</td>
                    <td>M.Sc. BOTANY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>543</td>
                    <td>M.Sc. BOTANY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>544</td>
                    <td>M.Sc. BOTANY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>552</td>
                    <td>M.Sc. ZOOLOGY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>553</td>
                    <td>M.Sc. ZOOLOGY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>554</td>
                    <td>M.Sc. ZOOLOGY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>562</td>
                    <td>M.Sc. COMPUTER SCIENCE (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>563</td>
                    <td>M.Sc. COMPUTER SCIENCE (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>564</td>
                    <td>M.Sc. COMPUTER SCIENCE (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>571</td>
                    <td>M.Sc. MICROBIOLOGY (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>572</td>
                    <td>M.Sc. MICROBIOLOGY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>573</td>
                    <td>M.Sc. MICROBIOLOGY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>574</td>
                    <td>M.Sc. MICROBIOLOGY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>582</td>
                    <td>M.Sc. GEOLOGY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>583</td>
                    <td>M.Sc. GEOLOGY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>584</td>
                    <td>M.Sc. GEOLOGY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>592</td>
                    <td>M.COM (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>593</td>
                    <td>M.COM (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>594</td>
                    <td>M.COM (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>601</td>
                    <td>M.S.W. (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>602</td>
                    <td>M.S.W. (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>603</td>
                    <td>M.S.W. (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>604</td>
                    <td>M.S.W. (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>612</td>
                    <td>M.Sc. BIOTECHNOLOGY (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>613</td>
                    <td>M.Sc. BIOTECHNOLOGY (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>614</td>
                    <td>M.Sc. BIOTECHNOLOGY (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>621</td>
                    <td>
                      MASTER OF COMPUTER APPLICATION (FIRST SEMESTER) (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>622</td>
                    <td>MASTER OF COMPUTER APPLICATION (SECOND SEMESTER)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>623</td>
                    <td>
                      MASTER OF COMPUTER APPLICATION (THIRD SEMESTER) (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>624</td>
                    <td>MASTER OF COMPUTER APPLICATION (FOURTH SEMESTER)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>632</td>
                    <td>
                      MASTER OF SCIENCE IN INFORMATION TECHNOLOGY (SECOND
                      SEMESTER){" "}
                    </td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>633</td>
                    <td>
                      MASTER OF SCIENCE IN INFORMATION TECHNOLOGY (THIRD
                      SEMESTER){" "}
                    </td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>634</td>
                    <td>
                      MASTER OF SCIENCE IN INFORMATION TECHNOLOGY (FOURTH
                      SEMESTER){" "}
                    </td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>640</td>
                    <td>BACHELOR OF EDUCATION (FIRST YEAR)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>641</td>
                    <td>BACHELOR OF EDUCATION (SECOND YEAR)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>651</td>
                    <td>MASTER OF EDUCATION (Sem II) </td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>652</td>
                    <td>MASTER OF EDUCATION (Sem III)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>653</td>
                    <td>MASTER OF EDUCATION (Sem IV)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>662</td>
                    <td>M.A. EDUCATION (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>663</td>
                    <td>M.A. EDUCATION (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>664</td>
                    <td>M.A. EDUCATION (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>671</td>
                    <td>M.A. YOGA (FIRST SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>672</td>
                    <td>M.A. YOGA (SECOND SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>673</td>
                    <td>M.A. YOGA (THIRD SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>674</td>
                    <td>M.A. YOGA (FOURTH SEMESTER)</td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>681</td>
                    <td>
                      MASTER OF LIBRARY &amp; INFORMATION SCIENCE (FIRST
                      SEMESTER)
                    </td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>682</td>
                    <td>
                      MASTER OF LIBRARY &amp; INFORMATION SCIENCE (SECOND
                      SEMESTER)
                    </td>
                    <td>PG-SEM</td>
                  </tr>
                  <tr>
                    <td>709</td>
                    <td>B.C.A. PART-III (THREE) (OLD COURSE)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>710</td>
                    <td>B.C.A. PART-II (TWO) (ELIGIBILITY)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>711</td>
                    <td>B.C.A. PART-III (THREE) (ELIGIBILITY)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>801</td>
                    <td>B.Sc. (Hons) Computer Science -Semester I (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>802</td>
                    <td>B.Sc. (Hons) Computer Science -Semester II</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>803</td>
                    <td>B.Sc. (Hons) Computer Science -Semester III (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>804</td>
                    <td>B.Sc. (Hons) Computer Science -Semester IV</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>805</td>
                    <td>B.Sc. (Hons) Computer Science -Semester V (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>806</td>
                    <td>B.Sc. (Hons) Computer Science -Semester VI</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>811</td>
                    <td>
                      B.Sc. (Hons) Food Processing Technology-Semester I (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>812</td>
                    <td>B.Sc. (Hons) Food Processing Technology-Semester II</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>813</td>
                    <td>
                      B.Sc. (Hons) Food Processing Technology-Semester III (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>814</td>
                    <td>B.Sc. (Hons) Food Processing Technology-Semester IV</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>815</td>
                    <td>
                      B.Sc. (Hons) Food Processing Technology-Semester V (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>816</td>
                    <td>B.Sc. (Hons) Food Processing Technology-Semester VI</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>821</td>
                    <td>B.COM (Hons) -Semester I (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>822</td>
                    <td>B.COM (Hons) -Semester II</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>823</td>
                    <td>B.COM (Hons) -Semester III (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>824</td>
                    <td>B.COM (Hons) -Semester IV</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>825</td>
                    <td>B.COM (Hons) -Semester V (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>826</td>
                    <td>B.COM (Hons) -Semester VI</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>831</td>
                    <td>
                      B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER I (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>832</td>
                    <td>B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER II</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>833</td>
                    <td>
                      B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER III (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>834</td>
                    <td>B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER IV</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>835</td>
                    <td>
                      B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER V (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>836</td>
                    <td>B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER VI</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>837</td>
                    <td>
                      B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER VII (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>838</td>
                    <td>B.H.M. (UNDER 4 YEAR DEGREE COURSE) -SEMESTER VIII</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>841</td>
                    <td>B.Sc. Honours Microbiology Semester-I (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>842</td>
                    <td>B.Sc. Honours Microbiology Semester-II</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>843</td>
                    <td>B.Sc. Honours Microbiology Semester-III (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>844</td>
                    <td>B.Sc. Honours Microbiology Semester-IV</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>851</td>
                    <td>
                      MBA- TOURISM AND TRAVEL MANAGEMENT (MBA-TTM) -SEMESTER I
                      (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>852</td>
                    <td>
                      MBA- TOURISM AND TRAVEL MANAGEMENT (MBA-TTM) -SEMESTER II
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>853</td>
                    <td>
                      MBA- TOURISM AND TRAVEL MANAGEMENT (MBA-TTM) -SEMESTER III
                      (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>854</td>
                    <td>
                      MBA- TOURISM AND TRAVEL MANAGEMENT (MBA-TTM) -SEMESTER IV
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>855</td>
                    <td>MASTER OF BUSINESS ADMINISTRATION Semester I (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>857</td>
                    <td>
                      MASTER OF BUSINESS ADMINISTRATION Semester III (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>859</td>
                    <td>
                      Master of Technology in Computer Science Semester I (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>860</td>
                    <td>
                      Master of Technology in Computer Science Semester II
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>861</td>
                    <td>
                      Master of Technology in Computer Science Semester III
                      (UTD)
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>862</td>
                    <td>
                      Master of Technology in Computer Science Semester IV
                    </td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>863</td>
                    <td>B.Sc. (Hons.) Biotechnology Semester I (UTD)</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>864</td>
                    <td>B.Sc. (Hons.) Biotechnology Semester II</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>866</td>
                    <td>B.Sc. (Hons.) Biotechnology Semester IV</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>868</td>
                    <td>B.Sc. (Hons.) Biotechnology Semester VI</td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>871</td>
                    <td>
                      BACHELORS OF HOTEL MANAGEMENT AND CATERING TECHNOLOGY
                      (UNDER 4 YEAR DEGREE COURSE) -SEMESTER I (UTD)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>872</td>
                    <td>
                      BACHELORS OF HOTEL MANAGEMENT AND CATERING TECHNOLOGY
                      (UNDER 4 YEAR DEGREE COURSE)SEMESTER II (UT)
                    </td>
                    <td>UTD-UG</td>
                  </tr>
                  <tr>
                    <td>891</td>
                    <td>M.SC. I SEM. COMPUTER SCIENCE (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>892</td>
                    <td>M.SC. II SEM. COMPUTER SCIENCE</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>910</td>
                    <td>LL.B. PART ONE (I SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>911</td>
                    <td>LL.B. PART ONE (II SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>912</td>
                    <td>LL.B. PART TWO (I SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>913</td>
                    <td>LL.B. PART TWO (II SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>914</td>
                    <td>LL.B. PART THREE (I SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>915</td>
                    <td>LL.B. PART THREE (II SEM.)</td>
                    <td>LAW-PG</td>
                  </tr>
                  <tr>
                    <td>931</td>
                    <td>B.COM. LL.B. FIRST SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>932</td>
                    <td>B.COM. LL.B. SECOND SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>933</td>
                    <td>B.COM. LL.B. THIRD SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>934</td>
                    <td>B.COM. LL.B. FOURTH SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>935</td>
                    <td>B.COM. LL.B. FIFTH SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>936</td>
                    <td>B.COM. LL.B. SIXTH SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>937</td>
                    <td>B.COM. LL.B. SEVENTH SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>938</td>
                    <td>B.COM. LL.B. EIGHT SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>939</td>
                    <td>B.COM. LL.B. NINETH SEMESTER (NEW COURSE)</td>
                    <td>LAW-UG</td>
                  </tr>
                  <tr>
                    <td>1001</td>
                    <td>B.A. PART-I (ONE) (ADDITIONAL SUBJECT)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>1003</td>
                    <td>B.A. PART-III (THREE) (ADDITIONAL SUBJECT)</td>
                    <td>UG</td>
                  </tr>
                  <tr>
                    <td>1372</td>
                    <td>M.COM II SEM. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1373</td>
                    <td>M.COM III SEM. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1374</td>
                    <td>M.COM IV SEM. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1471</td>
                    <td>M.SC. I SEM. COMPUTER SCIENCE (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1472</td>
                    <td>M.SC. II SEM. COMPUTER SCIENCE (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1473</td>
                    <td>M.SC. III SEM. COMPUTER SCIENCE (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1474</td>
                    <td>M.SC. IV SEM. COMPUTER SCIENCE (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1771</td>
                    <td>M.SC. I SEM. FOOD PROCESSING &amp; TECH. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1772</td>
                    <td>M.SC. II SEM. FOOD PROCESSING &amp; TECH. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1773</td>
                    <td>M.SC. III SEM. FOOD PROCESSING &amp; TECH. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>1774</td>
                    <td>M.SC. IV SEM. FOOD PROCESSING &amp; TECH. (UTD)</td>
                    <td>UTD-PG</td>
                  </tr>
                  <tr>
                    <td>2505</td>
                    <td>PRE PH.D COURSE WORK EDUCATION </td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2506</td>
                    <td>
                      PRE PH.D COURSE WORK COMPUTER SCIENCE &amp; APPLICATION
                    </td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2507</td>
                    <td>PRE PH.D COURSE WORK MICROBIOLOGY</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2508</td>
                    <td>PRE PH.D COURSE WORK CHEMISTRY</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2509</td>
                    <td>PRE PH.D COURSE WORK HINDI</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2510</td>
                    <td>PRE PH.D COURSE WORK MATHS</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2511</td>
                    <td>PRE PH.D COURSE WORK BIOLOGY</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2512</td>
                    <td>PRE PH.D COURSE WORK ZOOLOGY</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2513</td>
                    <td>PRE PH.D COURSE WORK POLITICAL SCIENCE</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2514</td>
                    <td>PRE PH.D COURSE WORK PHYSICS</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2515</td>
                    <td>PRE PH.D COURSE WORK BOTANY </td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2516</td>
                    <td>PRE PH.D COURSE WORK HISTORY</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2517</td>
                    <td>PRE PH.D COURSE WORK ECONOMICS</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2518</td>
                    <td>PRE PH.D COURSE WORK COMMERCE</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>2519</td>
                    <td>PRE PH.D COURSE WORK ENGLISH</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>3501</td>
                    <td>PRE PH.D COURSE WORK-GEOGRAPHY</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>3511</td>
                    <td>PRE PH.D COURSE WORK-SOCIOLOGY </td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>3521</td>
                    <td>PRE PH.D COURSE WORK-HOME SCIENCE</td>
                    <td>PHD</td>
                  </tr>
                  <tr>
                    <td>6501</td>
                    <td>MASTER OF EDUCATION (SEM I) OLD COURSE</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>6503</td>
                    <td>MASTER OF EDUCATION (Sem III) (OLD COURSE)</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>6504</td>
                    <td>MASTER OF EDUCATION (Sem IV) (OLD COURSE)</td>
                    <td>PG</td>
                  </tr>
                  <tr>
                    <td>O094</td>
                    <td>LL.B. PART TWO (II SEM.) (OLD COURSE)</td>
                    <td>LAW-PG</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default Course;