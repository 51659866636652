// src/pages/Home.js
import React from 'react';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";
import Header from '../../components/Header';

const DeanOfStudentsWelfare= observer(() => {
  return (
    <div>
      <Header/>
        <>
  <section className="innerbanner" />
  <div className="container-fluid">
    <div className="row2">
      <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
        <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <h3 className="main-head">Administration</h3>
          <ul
            className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
            id="menu"
          >
            <li className="w-100">
              <a
                href="/HonbleChancellor"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Hon'ble Chancellor</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="#submenu1"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-speedometer2" />{" "}
                <span className="ms-1  d-sm-inline gd">Vice Chancellor</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu1"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/ViceChancellor"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Vice Chancellor
                    </span> 1{" "}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/FormerViceChancellor"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Former Vice Chancellor
                    </span>{" "}
                    2{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="/Registrar"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline gd">Registrar</span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="#submenu2"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle "
              >
                <i className="fs-4 bi-bootstrap" />{" "}
                <span className="ms-1  d-sm-inline gd">Executive Council</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu2"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/ECMember"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Member Of The Executive Council
                    </span>{" "}
                    1
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/ECNotification"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Notification And Minutes Of Meeting Of The Executive
                      Council
                    </span>{" "}
                    2
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="#submenu8"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-grid" />{" "}
                <span className="ms-1  d-sm-inline gd">Academic Council</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu8"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/ACMember"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Member Of The Academc Council
                    </span>{" "}
                    1
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/ACNotification"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Notification And Minutes Of Meeting Of The Academic
                      Council
                    </span>{" "}
                    2
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="/DirectorPhysicalEducation"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline gd">
                  Director,Physical Education
                </span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="/ControllerOfExamination"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline gd">
                  Controller Of Examination
                </span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="/FinanceOfficer"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline gd">Finance Officer</span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="/Deputyregistrar"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline gd">Deputy Registrar</span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="/AssistantRegistrar"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-table" />{" "}
                <span className="ms-1  d-sm-inline gd">
                  Assistant Registrar
                </span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="/DeanOfStudentsWelfare"
                className="nav-link px-0 align-middle"
                style={{
                  borderLeft: "4px solid #03356E",
                  backgroundColor: "#03356E",
                  pointerEvents: "none"
                }}
              >
                <i className="fs-4 bi-table" />{" "}
                <span
                  className="ms-1  d-sm-inline gd"
                  style={{ color: "#fff" }}
                >
                  Dean Of Students' Welfare
                </span>{" "}
              </a>
            </li>
            <li className="w-100">
              <a
                href="#submenu3"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-grid" />{" "}
                <span className="ms-1  d-sm-inline gd">Finance Committee</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu3"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/FCMember"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Member Of The Finance Committee
                    </span>{" "}
                    1
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/FCNotification"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Notification And Minutes Of Meeting Of The Academic
                      Council
                    </span>{" "}
                    2
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="#submenu5"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-grid" />{" "}
                <span className="ms-1  d-sm-inline gd">Dean Of Schools</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu5"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/DOSMember"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Member Of The Dean Of Schools
                    </span>{" "}
                    1
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/DOSNotification"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Notification And Minutes Of Meeting Of The Dean Of Schools
                    </span>
                    2
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="#submenu6"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-grid" />{" "}
                <span className="ms-1  d-sm-inline gd">Board Of Studies</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu6"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/BOSMember"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Member Of The Board Of Studies
                    </span>{" "}
                    1
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="/BOSNotification"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Notification And Minutes Of Meeting Of The Board Of
                      Studies
                    </span>{" "}
                    2
                  </a>
                </li>
              </ul>
            </li>
            <li className="w-100">
              <a
                href="#submenu7"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-grid" />{" "}
                <span className="ms-1  d-sm-inline gd">Different Cells</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg
                  style={{ width: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
              </a>
              <ul
                className="collapse nav flex-column ms-1"
                id="submenu7"
                data-bs-parent="#menu"
              >
                <li className="w-100">
                  <a
                    href="/DCTrainingcell"
                    className="nav-link px-0"
                  >
                    {" "}
                    <span className=" d-sm-inline asd">
                      Training And Placement Cell
                    </span>{" "}
                    1
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <div className="row1 mx-auto">
        <h3 className="main-head">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
          </svg>
          Dean of Students' Welfare
        </h3>
        <div className="img-container">
          <img src="image/Dr-H-S-Hota (1).jpg" alt="Dr-H-S-Hota " />
        </div>
        <p className="p-head">
          Students are fundamental unit and pillars of the University. DSW
          office of Atal Bihari Vajpayee Vishwavidhyalaya has full-fledged
          inter-mediation between students and administration by giving them
          close supervision. Students plays pivotal role in the development of a
          country. Large quantity of students are passed out every year from
          this university but DSW office is committed to provide qualitative
          opportunities for all-round development of the students. Effective
          participation with respect to sports through inter- state and
          interuniversity competitions ,hostel, cultural and literary festivals
          by organizing youth festivals, research through organizing research
          workshops, well being of life etc under guidance of expertise is
          ensured here. DSW is always actively indulge in promoting and
          coordinating student welfare activities. The primary motive of DSW is
          to shape students personality by assuming them as a social asset and
          nurture their talents by preparing them for future challenges. With
          respect to the continuous extra- curricular development of the
          students I firmly believe that " if we want to over challenges, we
          need to change and change is the only permanent thing in this world.
        </p>
        <div className="content">
          <h3 className="cont-head">Dr. H.S. Hota</h3>
          <p style={{ textDecorationColor: "blue" }}>
            Dean of Students' Welfare
          </p>
        </div>
        <div className="phone1">
          <p className="phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              viewBox="0 0 512 512"
            >
              <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" />
            </svg>
            <span>9425222658</span>
          </p>
        </div>
        <div className="phone1">
          <p className="phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              viewBox="0 0 512 512"
            >
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
            </svg>
            <span>proffhota@gmail.com</span>
          </p>
        </div>
        <div className="table-content">
          <h4>
            Facilities and activities conducted for students under the
            supervision of Dean Student Welfare Office
          </h4>
          <table>
            <tbody>
              <tr>
                <th>s.no</th>
                <th>Title</th>
                <th>Upload Date</th>
                <th>Document</th>
              </tr>
              <tr>
                <td>1</td>
                <td>YUWA MAHOTSAWA</td>
                <td>18-02-2018</td>
                <td>
                  <a
                    target="_blank"
                    href="https://abvv.ac.in/uploads/PRATIVEDAN%20YUWA%20MAHOTSAWA%202018.pdf"
                  >
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>NK PATEL VYAKYAN</td>
                <td>25-05-2021</td>
                <td>
                  <a href="https://abvv.ac.in/uploads/PRATIVEDAN%20NK%20PATEL%20VYAKYAN%202021.pdf">
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>ROHNI VAJPYEE SMRITI VYAKYAN</td>
                <td>28-07-2021</td>
                <td>
                  <a href="https://abvv.ac.in/uploads/PRATIVEDAN%20ROHNI%20VAJPYEE%20SMRITI%20VYAKYAN%202021.pdf">
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>CG LECTURE</td>
                <td>07-02-2023</td>
                <td>
                  <a href="https://abvv.ac.in/uploads/PRATIVEDAN%20VISHWA%20AADIWASHI%20DIWAS%2009.07.23.pdf" />
                  PRATIVEDAN
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>CG LECTURE</td>
                <td>09-07-2023</td>
                <td>
                  <a href="https://abvv.ac.in/uploads/PRATIVEDAN%20VISHWA%20AADIWASHI%20DIWAS%2009.07.23.pdf">
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>KARGIL VIJAY DIWAS</td>
                <td>26-07-2023</td>
                <td>
                  <a href="//abvv.ac.in/uploads/PRATIVEDAN%20KARGIL%20VIJAY%20DIWAS%2026.07.23.pdf">
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>ATAL JI PUNYA TITHI</td>
                <td>16-08-2023</td>
                <td>
                  <a href="https://abvv.ac.in/uploads/ATAL%20JI%20PUNYA%20TITHI%2016.08.23_20230211070715.pdf">
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>CHANDRAYAN 3 SOFT LANDING</td>
                <td>23-08-2023</td>
                <td>
                  <a href="https://abvv.ac.in/uploads/PRATIVEDAN%20CHANDRAYAN%203%20SOFT%20LANDING.pdf">
                    PRATIVEDAN
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

    </div>
  );
}
);

export default DeanOfStudentsWelfare;