// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const UniversityNewsLetter= observer(() => {
  return (
    <div>
      <Header/>
      <>
  <section className="innerbanner" />
  <div className="container-fluid">
    <div className="row2">
      <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
        <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <h3 className="main-head">Media</h3>
          <ul
            className="nav nav-pills  mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
            id="menu"
          >
            <li className="w-100">
              <a
                href="/PhotoGallary"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Photo Gallery</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/VideoGallary"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Video Gallery</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/UniversityInNews"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">University in News</span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/UniversityNewsLetter"
                className="nav-link align-middle px-0"
                style={{
                  borderLeft: "4px solid #03356E",
                  backgroundColor: "#03356E",
                  pointerEvents: "none"
                }}
              >
                <i className="fs-4 bi-house" />{" "}
                <span
                  className="ms-1  d-sm-inline gd"
                  style={{ color: "#fff" }}
                >
                  University News Letter
                </span>
              </a>
            </li>
            <li className="w-100">
              <a
                href="/AnnualReport"
                className="nav-link align-middle px-0"
              >
                <i className="fs-4 bi-house" />{" "}
                <span className="ms-1  d-sm-inline gd">Annual Report</span>
              </a>
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <div className="row1 mx-auto">
        <h3 className="main-head">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
            <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
          University News Letter List
        </h3>
        <form
          action="/n/University%20Annual%20Report"
          className=""
          method="get"
        >
          <table className="table table-warning">
            <tbody>
              <tr>
                <td>
                  <div className="form-group">
                    <label htmlFor="pageSize">Display Per Page</label>
                    <select
                      className="form-control"
                      id="pagesize"
                      name="pagesize"
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option selected="selected" value={20}>
                        20
                      </option>
                      <option value={25}>25</option>
                      <option value={30}>30</option>
                      <option value={35}>35</option>
                      <option value={40}>40</option>
                      <option value={45}>45</option>
                      <option value={50}>50</option>
                      <option value={55}>55</option>
                      <option value={60}>60</option>
                      <option value={65}>65</option>
                      <option value={70}>70</option>
                      <option value={75}>75</option>
                      <option value={80}>80</option>
                      <option value={85}>85</option>
                      <option value={90}>90</option>
                      <option value={95}>95</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <label htmlFor="name">Subject</label>
                    <input
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Subject"
                      type="text"
                      defaultValue=""
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <label htmlFor="month">Month</label>
                    <select className="form-control" id="month" name="month">
                      <option selected="selected" value={0}>
                        All
                      </option>
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <label htmlFor="year">Year</label>
                    <select className="form-control" id="year" name="year">
                      <option selected="selected" value={0}>
                        All
                      </option>
                      <option value={2019}>2019</option>
                      <option value={2020}>2020</option>
                      <option value={2021}>2021</option>
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <label htmlFor="search"></label>
                    <button
                      type="submit"
                      className="btn btn-success form-control"
                    >
                      Search!
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>{" "}
        <div className="container">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2019 Jan June_11zon_20231710105126.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2019-jan-june_20231710105125.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 8 (1) जनवरी- जून 2019 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2019 Jan June_11zon_20231710105126.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 8 (1) जनवरी- जून 2019 त्रैमासिक पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    30/06/2019{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2018 July Dec_11zon_20231710104707.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2018 July Dec._20231710104706.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 6 (2) जुलाई-दिसंबर 2018 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2018 July Dec_11zon_20231710104707.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 6 (2) जुलाई-दिसंबर 2018 त्रैमासिक
                      पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    31/12/2018{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2018  Jan June_11zon_20231710104821.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2018 Jan-June_20231710104821.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 6 (1) जनवरी- जून 2018 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2018  Jan June_11zon_20231710104821.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 6 (1) जनवरी- जून 2018 त्रैमासिक पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>30/06/2018{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2017 July Dec_20231710102650.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2017 July-Dec_20231710102355.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 6 (2) जुलाई-दिसंबर 2017 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2017 July Dec_20231710102650.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 6 (2) जुलाई-दिसंबर 2017 त्रैमासिक
                      पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    31/12/2017{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100" />
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2017  Jan June_20231710102115.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2017 Jan-June_20231710102057.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 6 (1) जनवरी- जून 2017 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2017  Jan June_20231710102115.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 6 (1) जनवरी- जून 2017 त्रैमासिक पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    30/06/2017{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2016 July Dec_20231710101937.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2016 July Dec_20231710101934.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 5 (2) जुलाई-दिसंबर 2016 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2016 July Dec_20231710101937.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 5 (2) जुलाई-दिसंबर 2016 त्रैमासिक
                      पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    31/12/2016{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2015 July Dec_20231710100915.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2015 July-Dec (1)_20231710101355.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 4 (2)  जुलाई-दिसंबर 2015 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2015 July Dec_20231710100915.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 4 (2) जुलाई-दिसंबर 2015 त्रैमासिक
                      पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    31/12/2015{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2015 Jan June_20231710100348.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2015 Jan-June_20231710100733.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 4 (1) जनवरी- जून 2015 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2015 Jan June_20231710100348.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 4 (1) जनवरी- जून 2015 त्रैमासिक पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    30/06/2015{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100" />
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2014 July Dec_20231710100155.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2014 July Dec-1_20231710100200.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 3 (2) जुलाई-दिसम्बर 2014 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2014 July Dec_20231710100155.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 3 (2) जुलाई-दिसम्बर 2014 त्रैमासिक
                      पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    31/12/2014{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2014 Jan June_20231710095958.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2014 Jan-June_20231710100008.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 3 (1) जनवरी-जून 2014 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2014 Jan June_20231710095958.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 3 (1) जनवरी-जून 2014 त्रैमासिक पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    30/06/2014{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/Uploads/2013 July Dec_20231710095631.pdf"
                  target="_blank"
                >
                  <img
                    src="/Uploads/2013_20231710095641.png"
                    className="card-img-top"
                    alt="न्यूज लेटर संस्करण 2 (1)  जुलाई-दिसंबर  2013 त्रैमासिक पत्रिका"
                  />
                </a>{" "}
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="/Uploads/2013 July Dec_20231710095631.pdf"
                      target="_blank"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      न्यूज लेटर संस्करण 2 (1) जुलाई-दिसंबर 2013 त्रैमासिक
                      पत्रिका{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>News Date:</b>
                    31/12/2013{" "}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="col-sm-10">
              <div className="center">
                <div className="pagination-container">
                  <ul className="pagination">
                    <li className="PagedList-skipToPrevious">
                      <a
                        href="/home/newsletter/university-news-letter?page=1&pagesize=12&year=0&month=0"
                        rel="prev"
                      >
                        «
                      </a>
                    </li>
                    <li>
                      <a href="/home/newsletter/university-news-letter?page=1&pagesize=12&year=0&month=0">
                        1
                      </a>
                    </li>
                    <li className="active">
                      <a>2</a>
                    </li>
                  </ul>
                </div>
                Page 2 of 2
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

      <Footer/>
    </div>
  );
}
);

export default UniversityNewsLetter;