// src/pages/Home.js
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { observer } from "mobx-react";

const PhotoGallary= observer(() => {
  return (
    <div>
      <Header/>
      <>
  <section className="innerbanner" />
  <div className="row" style={{ width: "100%" }}>
    <div className="container-fluid">
      <div className="row2">
        <div className="col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-light ">
          <div className=" d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <h3 className="main-head">Media</h3>
            <ul
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
              id="menu"
            >
              <li className="w-100">
                <a
                  href="/PhotoGallary"
                  className="nav-link align-middle px-0"
                  style={{
                    borderLeft: "4px solid #03356E",
                    backgroundColor: "#03356E",
                    pointerEvents: "none"
                  }}
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span
                    className="ms-1  d-sm-inline asd"
                    style={{ color: "#fff" }}
                  >
                    Photo Gallery
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/VideoGallary"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Video Gallery</span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/UniversityInNews"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    University in News
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/UniversityNewsLetter"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">
                    University News Letter
                  </span>
                </a>
              </li>
              <li className="w-100">
                <a
                  href="/AnnualReport"
                  className="nav-link align-middle px-0"
                >
                  <i className="fs-4 bi-house" />{" "}
                  <span className="ms-1  d-sm-inline asd">Annual Report</span>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
        <div className="row1 mx-auto">
          <h3 className="main-head">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
            Gallery List
          </h3>
          <form action="/n/recognition">
            <table className="table table-warning">
              <tbody>
                <tr>
                  <td>
                    <div className="form-group">
                      <label htmlFor="pageSize">Display Per Page</label>
                      <select
                        className="form-control"
                        id="pagesize"
                        name="pagesize"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option selected="selected" value={20}>
                          20
                        </option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                        <option value={40}>40</option>
                        <option value={45}>45</option>
                        <option value={50}>50</option>
                        <option value={55}>55</option>
                        <option value={60}>60</option>
                        <option value={65}>65</option>
                        <option value={70}>70</option>
                        <option value={75}>75</option>
                        <option value={80}>80</option>
                        <option value={85}>85</option>
                        <option value={90}>90</option>
                        <option value={95}>95</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="name">Subject</label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Subject"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="month">Month</label>
                      <select className="form-control" id="month" name="month">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="year">Year</label>
                      <select className="form-control" id="year" name="year">
                        <option selected="selected" value={0}>
                          All
                        </option>
                        <option value={2019}>2019</option>
                        <option value={2020}>2020</option>
                        <option value={2021}>2021</option>
                        <option value={2022}>2022</option>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <label htmlFor="search"></label>
                      <button
                        type="submit"
                        className="btn btn-success form-control"
                      >
                        Search!
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="grid">
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/uploads/2023-05-25 at 5.24.25 PM.jpeg"
                  data-toggle="lightbox"
                  data-gallery="hidden-images-2"
                >
                  <img
                    src="../image/photoGrideImage1.jpeg"
                    className="card-img-top"
                  />
                </a>
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="javascript:void(0);"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      तृतीय स्मृति व्याख्यान "प्रशासन और आंतरिक सुरक्षा"{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>Date:</b>
                    25/05/2023 <br />
                    <button
                      className="btn btn-link"
                      data-bs-toggle="collapse"
                      href="#2"
                      role="button"
                      aria-expanded="false"
                      aria-controls={2}
                    >
                      Description
                    </button>
                  </p>
                  <div id={2} className="collapse">
                    <div className="card card-body">
                      <p>
                        शहीद नन्द कुमार पटेल लोक प्रशासन एवं अपराध अध्ययन शोध
                        पीठ अटल बिहारी वाजपेयी विश्वविद्यालय, बिलासपुर द्वारा
                        आयोजित&nbsp;
                      </p>
                      <p>तृतीय स्मृति व्याख्यान "प्रशासन और आंतरिक सुरक्षा"</p>
                      <p>गुरुवार, 25 मई 2023, दोपहर 03:00 बजे&nbsp;</p>
                      <p>
                        स्थान : सभागार, अटल बिहारी वाजपेयी विश्वविद्यालय, कोनी,
                        बिलासपुर (छ.ग.) 495009
                      </p>
                      <p>
                        मुख्य अतिथि एवं मुख्य वक्ता : श्री राजेश मिश्रा (IPS)
                        माननीय संचालक, राज्य न्यायालयिक विज्ञान प्रयोगशाला
                        रायपुर (छ.ग.)
                      </p>
                      <p>
                        अध्यक्षता : आचार्य अरुण दिवाकर नाथ वाजपेयी माननीय
                        कुलपति, अटल बिहारी वाजपेयी विश्वविद्यालय बिलासपुर (छ.ग.)
                      </p>
                      <p>
                        निदेशक :डॉ. विवेक वाजपेयी शहीद नंद कुमार पटेल शोधपीठ,
                        उद्योगपति एवं समाज सेवक
                      </p>
                      <p>
                        कुल सचिव : शैलेन्द्र दुबे अटल बिहारी वाजपेयी
                        विश्वविद्यालय, बिलासपुर (छ.ग.)
                      </p>
                      <p>
                        संयोजक :डॉ. सौमित्र तिवारी अटल बिहारी वाजपेयी
                        विश्वविद्यालय, बिलासपुर (छ.ग.)
                      </p>
                    </div>
                  </div>
                  <p />
                  <div
                    data-toggle="lightbox"
                    data-gallery="hidden-images-2"
                    data-remote="/uploads/WhatsApp Image 2023-05-25 at 5.22.50 PM_2023265105303.jpeg"
                    data-title=""
                  />
                  <div
                    data-toggle="lightbox"
                    data-gallery="hidden-images-2"
                    data-remote="/uploads/WhatsApp Image 2023-05-25 at 5.23.27 PM_2023265105313.jpeg"
                    data-title=""
                  />
                  <div
                    data-toggle="lightbox"
                    data-gallery="hidden-images-2"
                    data-remote="/uploads/WhatsApp Image 2023-05-25 at 5.23.53 PM_2023265105323.jpeg"
                    data-title=""
                  />
                  <div
                    data-toggle="lightbox"
                    data-gallery="hidden-images-2"
                    data-remote="/uploads/WhatsApp Image 2023-05-25 at 5.24.25 PM_2023265105336.jpeg"
                    data-title=""
                  />
                  <div
                    data-toggle="lightbox"
                    data-gallery="hidden-images-2"
                    data-remote="/uploads/WhatsApp Image 2023-05-25 at 5.24.58 PM_2023265105346.jpeg"
                    data-title=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 p-col">
              <div className="card card1">
                <a
                  href="/uploads/WhatsApp Image 2023-05-14 at 10.51.43 PM_2023235112824.jpeg"
                  data-toggle="lightbox"
                  data-gallery="hidden-images-1"
                >
                  <img
                    src="../image/photoGrideImage2.jpeg"
                    className="card-img-top"
                  />
                </a>
                <div className="card-body">
                  <h5 className="card-title">
                    <a
                      href="javascript:void(0);"
                      className="text-decoration-none text-primary-emphasis"
                    >
                      Photo Gallery1{" "}
                    </a>
                  </h5>
                  <p className="card-text">
                    <b>Date:</b>
                    11/05/2023 <br />
                    <button
                      className="btn btn-link"
                      data-bs-toggle="collapse"
                      href="#1"
                      role="button"
                      aria-expanded="false"
                      aria-controls={1}
                    >
                      Description
                    </button>
                  </p>
                  <div id={1} className="collapse">
                    <div className="card card-body">
                      <p>
                        Testing
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          <br />
          <div className="col-sm-10">
            <div className="center">
              <div className="pagination-container">
                <ul className="pagination">
                  <li className="active">
                    <a>1</a>
                  </li>
                </ul>
              </div>
              Page 1 of 1
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

      <Footer/>
    </div>
  );
}
);

export default PhotoGallary;